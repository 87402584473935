import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles, WithStyles } from "@material-ui/core/styles";

const styles = {
  iconButton: {
  },
  deleteIcon: {
  },
};

export interface ToolbarItemAddProps {
  handleClick: () => void,
  tooltip: String
}

type Props = ToolbarItemAddProps & WithStyles<typeof styles>;

function ToolbarItemAdd(props: Props) {
  const { classes, tooltip } = props;
  return (
    <React.Fragment>
      <Tooltip title={tooltip}>
        <IconButton className={classes.iconButton} onClick={props.handleClick}>
          <AddIcon className={classes.deleteIcon} />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default withStyles(styles, { name: "ToolbarItemAdd" })(ToolbarItemAdd);