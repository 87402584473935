import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ClientSelect from './Inputs/ClientSelect';
import VolunteerSelect from './Inputs/VolunteerSelect';
import TitleSelect from './Inputs/TitleSelect';
import SubTitleSelect from './Inputs/SubTitleSelect';
import StatusSelect from './Inputs/StatusSelect';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { CaseData } from '../../data/CaseData';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface CaseFormProps {
    formId: string,
    apiEndpoint: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    data?: CaseData,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface CaseFormErrors {
    [key:string]: any,
    clientId: string,    
    firstVolunteerId: string,
    secondVolunteerId: string,
    title: string,
    titleOther: string,
    subtitle: string,
    subtitleOther: string,
    question: string,
    description: string,
    advice: string,
    toestemming: string,
    status: string,
}

export interface CaseFormState {
    caseId: number,
    clientId: string,
    firstVolunteerId: string,
    secondVolunteerId: string,
    title: string[],
    titleOther: string,
    subtitle: string[],
    subtitleOther: string,
    question: string,
    description: string,
    advice: string,
    toestemming: string,
    status: string,
    reachMethod: string,
    errors: CaseFormErrors
}

type Props = CaseFormProps & WithStyles<typeof styles>;

class CaseForm extends React.Component<Props, CaseFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            caseId: props.data ? props.data!.id : -1,
            clientId: props.data ? props.data!.obstructedClient.id.toString() : '',
            firstVolunteerId: props.data ? props.data!.firstVolunteer.id.toString() : this.props.user.profile.sub,
            secondVolunteerId: props.data ? props.data!.secondVolunteer.id.toString() : '',
            title: (props.data && props.data.title != null) ? props.data!.title.split(',') : [],
            titleOther: props.data ? props.data!.titleOther : '',
            subtitle: (props.data && props.data.subtitle != null) ? props.data.subtitle.split(',') : [],
            subtitleOther: props.data ? props.data!.subtitleOther : '',
            question: props.data ? props.data!.question : '',
            description: props.data ? props.data!.description : '',
            advice: props.data ? props.data!.advice : '',
            toestemming: props.data ? props.data!.toestemming : '',
            status: props.data ? props.data!.status : '',
            reachMethod: props.data ? props.data!.reachMethod : '',
            errors: {
                clientId: '',
                firstVolunteerId: '',
                secondVolunteerId: '',
                title: '',
                titleOther: '',
                subtitle: '',
                subtitleOther: '',
                question: '',
                description: '',
                advice: '',
                toestemming: '',
                status: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<CaseFormState, keyof CaseFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if(!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.apiEndpoint, data).perform();

        if (response.ok) {
            this.props.onSubmitSuccess();
        } else {
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { clientId, firstVolunteerId, secondVolunteerId, status, title, titleOther, subtitle, subtitleOther, question, description } = this.state;
        let isValid = true;

        currentErrors.clientId = UtilityString.nullOrEmpty(clientId) ? "Cliënt is verplicht" : '';
        currentErrors.firstVolunteerId = UtilityString.nullOrEmpty(firstVolunteerId) ? "Eerste behandelaar is verplicht" : '';
        currentErrors.secondVolunteerId = UtilityString.nullOrEmpty(secondVolunteerId) ? "Tweede behandelaar is verplicht" : '';
        currentErrors.status = UtilityString.nullOrEmpty(status) ? "Status is verplicht" : '';

        currentErrors.title = title.length == 0 ? "Kies tenminste 1 titel" : '';
        currentErrors.titleOther = (title.includes("Overig") && UtilityString.nullOrEmpty(titleOther)) ? "Verplicht omdat overig is geselecteerd" : '';
        currentErrors.subtitleOther = (subtitle.includes("Overig") && UtilityString.nullOrEmpty(subtitleOther)) ? "Verplicht omdat overig is geselecteerd" : '';

        currentErrors.question = UtilityString.nullOrEmpty(question) ? "Vraag is verplicht" : '';
        currentErrors.description = UtilityString.nullOrEmpty(description) ? "Omschrijving is verplicht" : '';

        for (let key in currentErrors) {
            if(!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;                
            }
        }
        this.setState({errors: currentErrors});
        return isValid;
    }

    public render() {
        const { classes, formId, data } = this.props;

        return (
            <div className={classes.root}>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    {data != undefined && 
                        <React.Fragment>
                            <Input id="Id" name="Id" value={data!.id} className={classes.hidden} />
                            <Input id="timeStamp" name="timestamp" value={data!.timeStamp} className={classes.hidden} />
                        </React.Fragment>                        
                    }

                    <Grid container spacing={24}>

                        <Grid item xs={12} sm={6} lg={4}>
                            <ClientSelect onChange={this.handleChange} value={this.state.clientId} error={this.state.errors.clientId} required />
                        </Grid>                        
                        <Grid item xs={12} sm={6} lg={4}>
                            <VolunteerSelect onChange={this.handleChange} label="Eerste behandelaar" name="firstVolunteerId" placeholder="Kies een vrijwilliger" value={this.state.firstVolunteerId} error={this.state.errors.firstVolunteerId} required />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <VolunteerSelect onChange={this.handleChange} label="Behandeld met" name="secondVolunteerId" placeholder="Kies een vrijwilliger" value={this.state.secondVolunteerId} error={this.state.errors.secondVolunteerId} required />
                        </Grid>
                        

                        <Grid item xs={12} sm={6} lg={4}>
                            <TitleSelect onChange={this.handleChange} values={this.state.title} error={this.state.errors.title} required />
                            <TextInput
                                id="title-other-text"
                                name="titleOther"
                                label="Overig, namelijk"
                                value={this.state.titleOther}
                                onChange={this.handleChange}
                                required={this.state.title.includes("Overig")}
                                error={this.state.errors.titleOther} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <SubTitleSelect onChange={this.handleChange} values={this.state.subtitle} error={this.state.errors.subtitle} />
                            <TextInput
                                id="subtitle-other-text"
                                name="subtitleOther"
                                label="Overig, namelijk"
                                value={this.state.subtitleOther}
                                onChange={this.handleChange}
                                required={this.state.subtitle.includes("Overig")}
                                error={this.state.errors.subtitleOther} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <StatusSelect value={this.state.status} onChange={this.handleChange} error={this.state.errors.status} required />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput
                                id="question-text"
                                value={this.state.question}
                                label="Vraag"
                                name="question"
                                multiline
                                rows="3"
                                onChange={this.handleChange}
                                error={this.state.errors.question}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput
                                id="toestemming-text"
                                value={this.state.toestemming}
                                label="Toestemming"
                                name="toestemming"
                                multiline
                                rows="3"
                                onChange={this.handleChange}
                                error={this.state.errors.toestemming}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextInput
                                id="description-text"
                                value={this.state.description}
                                label="Omschrijving"
                                name="description"
                                multiline
                                rows="4"
                                onChange={this.handleChange}
                                error={this.state.errors.description}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                id="advice-text"
                                value={this.state.advice}
                                label="Advies"
                                name="advice"
                                multiline
                                rows="4"
                                onChange={this.handleChange}
                                error={this.state.errors.advice}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput
                                id="reachMethod-text"
                                value={this.state.reachMethod}
                                label="Hoe is cliënt bij ons terechtgekomen?"
                                name="reachMethod"
                                multiline
                                rows="1"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        
                    </Grid>
                </form>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CaseForm));
