import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface ConfirmDialogProps {
    additionId: number | string,
    open: boolean,
    title: string,
    message: string,
    confirmButtonText: string,
    onClose: () => void,
    onConfirm: (id: any) => void,
}

class ConfirmDialog extends Component<ConfirmDialogProps> {

    render() {
        const { onClose, onConfirm, open, additionId, title, message, confirmButtonText } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button onClick={() => onConfirm(additionId)} color="secondary" autoFocus>{confirmButtonText}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmDialog;