export default class AuthRole
{
    public static fromName = (name: string) =>
    {
        return AuthRole.Values.find(e => e.name == name);
    }

    public static readonly USER = new AuthRole("user");
    public static readonly DEVELOPER = new AuthRole("developer");
    public static readonly ADMINISTRATOR = new AuthRole("administrator");
    public static readonly VOLUNTEER = new AuthRole("volunteer");

    public static readonly Values = [AuthRole.USER, AuthRole.DEVELOPER, AuthRole.ADMINISTRATOR, AuthRole.VOLUNTEER];

    public readonly name: string;

    private constructor(name: string)
    {
        this.name = name;
    }
}