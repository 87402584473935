import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

const subTitles = ["Omgang", "Gezag", "Hoofdverblijfplaats", "Hoorrecht", "Studiefinanciering", "Klachtrecht", "Informele rechtsingang", "Overig"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    },
    chips: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
});

export interface SubTitleSelectProps {
    onChange: (event: any) => void,
    values: string[],
    error?: string,
}

type Props = SubTitleSelectProps & WithStyles<typeof styles>;

function SubTitleSelect(props: Props) {
    const { classes, onChange, values, error } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="subtitle-select">Subcategorie</InputLabel>
            <Select
                multiple
                value={values}
                input={<Input name="subtitle" id="subtitle-select" />}
                onChange={onChange}
                displayEmpty
                name='subtitle'
                className={classes.selectEmpty}
                placeholder="Kies een subcategorie"
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {(selected! as string[]).map(value => <Chip key={value} label={value} className={classes.chip} />)}
                    </div>
                )}
            >
                {subTitles.map(subtitle => (
                    <MenuItem key={subtitle} value={subtitle}>{subtitle}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(SubTitleSelect);