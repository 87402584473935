import * as React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { DocumentData } from '../../../data/DocumentData';

export interface FileTableProps {
    tableTitle: string,
    tableOptions: MUIDataTableOptions,
    data: DocumentData[],
    onDelete: (id: number) => void,
    onDownload: (id: number) => void,
}

function FileTable(props: FileTableProps) {
    const { data, tableTitle, tableOptions } = props;

    const myData = data.map(item => [item.id, item.timestamp, item.fileName, item.fileType, item.note]);
    const columns = [
        {
            name: "identifier",
            label: "ID",
            options: {
                display: 'excluded' as 'excluded',
                sortDirection: 'desc' as 'desc'
            }
        },
        {
            name: "timestamp",
            label: "Datum",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "fileName",
            label: "Naam",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "fileType",
            label: "Type",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "note",
            label: "Notitie",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "actions",
            label: " ",
            options: {
                filter: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <React.Fragment>
                            <Tooltip title={"Downloaden"}>
                                <IconButton onClick={() => props.onDownload(tableMeta.rowData[0])}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Verwijderen"}>
                                <IconButton onClick={() => props.onDelete(tableMeta.rowData[0])}>
                                    <DeleteIcon  />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                setCellProps: () => {
                    return { 
                        align: 'right',
                     }
                }
            },
        },
    ];

    return (
        <MUIDataTable
            title={tableTitle}
            data={myData}
            columns={columns}
            options={tableOptions}
        />
    );
}

export default FileTable;
