import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";

const mapStateToProps = (state: ReduxState) => {
    return {
      configuration: state.configuration!
    }
  }

export interface ICalendarProps {    
  configuration: ReduxConfigurationEntity
}

class Calendar extends React.Component<ICalendarProps, any> {
  public render() {
    return (
      <div>
          <iframe src={this.props.configuration.clientSettings.googleAgendaUrl} width="100%" height={window.innerHeight * 0.8} frameBorder={0}></iframe>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Calendar)
