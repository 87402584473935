import * as React from 'react';
import { CaseAdditionData } from '../../../data/CaseAdditionData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CaseAddition from './CaseAddition';
import AddUpdateIcon from '@material-ui/icons/NoteAdd';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import AddCaseAdditionDialog from "../../components/dialogs/AddCaseAdditionDialog";
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import { connect } from 'react-redux';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import EditCaseAdditionDialog from "../../components/dialogs/EditCaseAdditionDialog";

const styles = (theme: Theme) => ({
    card: {
        padding: 0,
    },
    cardHeader: {
        paddingTop: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 4,
    },
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface CaseAdditionsProps {
    caseId: number,
    additions: CaseAdditionData[],
    onDataChanged: () => void,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface CaseAdditionState {
    addAdditionDialogOpen: boolean,
    deleteAdditionDialogOpen: boolean,
    deleteAdditionId: number,
    editAdditionDialogOpen: boolean,
    editAddition?: CaseAdditionData,
}

type Props = CaseAdditionsProps & WithStyles<typeof styles>;

class CaseAdditions extends React.Component<Props, CaseAdditionState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            addAdditionDialogOpen: false,
            deleteAdditionDialogOpen: false,
            deleteAdditionId: -1,
            editAdditionDialogOpen: false,
            editAddition: undefined,
        }
    }

    onAddAdditionSuccess = () => {
        this.setState({ addAdditionDialogOpen: false })
        this.props.onDataChanged();
    }

    onEditAdditionSuccess = () => {
        this.setState({ editAdditionDialogOpen: false, editAddition: undefined })
        this.props.onDataChanged();
    }

    openEditAdditionDialog = (addition: CaseAdditionData) => {
        this.setState({ editAddition: addition, editAdditionDialogOpen: true })
    }

    openDeleteAdditionDialog = (id: number) => {
        this.setState({ deleteAdditionId: id, deleteAdditionDialogOpen: true });
    }

    closeDeleteAdditionDialog = () => {
        this.setState({ deleteAdditionId: -1, deleteAdditionDialogOpen: false });
    }

    onDeleteAdditionConfirm = async (id: number) => {
        const deleteAdditionResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/caseaddition/delete?id=" + id).perform();
        if (deleteAdditionResponse.ok) {
            this.props.onDataChanged();
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ deleteAdditionDialogOpen: false, deleteAdditionId: -1 });
    }

    render() {
        const { classes, caseId, additions } = this.props;
        const { editAddition, deleteAdditionId } = this.state;

        return (
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.card}>

                        <Toolbar className={classes.cardHeader}>
                            <Grid container spacing={8} alignItems='center'>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Updates</Typography>
                                </Grid>
                            </Grid>

                            <Tooltip title="Update toevoegen">
                                <IconButton aria-label="AddUpdate" onClick={() => this.setState({ addAdditionDialogOpen: true })}>
                                    <AddUpdateIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>

                        {additions.map((addition, index) =>
                            <CaseAddition
                                key={"addition-" + index}
                                even={index % 2 == 0}
                                data={addition}
                                onEdit={this.openEditAdditionDialog}
                                onDelete={this.openDeleteAdditionDialog}
                            />
                        )}

                    </CardContent>
                </Card>

                <AddCaseAdditionDialog
                    caseId={caseId}
                    open={this.state.addAdditionDialogOpen}
                    onClose={() => this.setState({ addAdditionDialogOpen: false })}
                    onSuccess={this.onAddAdditionSuccess}
                />

                {deleteAdditionId != -1 && (
                    <ConfirmDialog
                        additionId={deleteAdditionId}
                        title="Update verwijderen?"
                        message="Weet u zeker dat u deze update wil verwijderen? Dit kan niet ongedaan worden gemaakt."
                        confirmButtonText="Verwijderen"
                        open={this.state.deleteAdditionDialogOpen}
                        onClose={this.closeDeleteAdditionDialog}
                        onConfirm={this.onDeleteAdditionConfirm}
                    />
                )}

                {editAddition != undefined && (
                    <EditCaseAdditionDialog
                        caseId={caseId}
                        data={editAddition!}
                        open={this.state.editAdditionDialogOpen}
                        onClose={() => this.setState({ editAdditionDialogOpen: false, editAddition: undefined })}
                        onSuccess={this.onEditAdditionSuccess}
                    />
                )}

            </div>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CaseAdditions));
