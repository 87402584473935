import * as React from 'react';
import { ClientData } from '../../../data/ClientData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UtilityString from '../../../utilities/UtilityString';

const styles = (theme: Theme) => ({
    card: {
        padding: theme.spacing.unit * 2,
    },
    cardHeader: {
        marginBottom: theme.spacing.unit * 2,
    },
    textBody: {
        whiteSpace: 'pre-line' as 'pre-line',
    }
});

export interface ClientOverviewProps {
    data: ClientData
}

type Props = ClientOverviewProps & WithStyles<typeof styles>;

function ClientOverview(props: Props) {
    const { data, classes } = props;

    let clientName = data.firstName;
    if(!UtilityString.nullOrEmpty(data.lastNamePrefix)) {
        clientName = clientName + " " + data.lastNamePrefix;
    }
    if(!UtilityString.nullOrEmpty(data.lastName)) {
        clientName = clientName + " " + data.lastName;
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container spacing={16} alignItems='center' className={classes.cardHeader}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Cliënt
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={32}>                    

                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Naam
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {clientName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Initialen
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.initials}
                        </Typography>
                    </Grid> 
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Leeftijd
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.age}
                        </Typography>
                    </Grid>            
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Geslacht
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.gender}
                        </Typography>
                    </Grid>                    

                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Geboortedatum
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.dateOfBirth}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Geboorteplaats
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.placeOfBirth}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component="p" color='textSecondary'>
                            Burgerservicenummer
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.bsn}
                        </Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            E-mailadres
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Telefoonnummer
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.phoneNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component="p" color='textSecondary'>
                            Telefoonnummer (Mobiel)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.phoneNumberMobile}
                        </Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Adres
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.streetName} {data.houseNumber} <br/>                           
                            {data.postalCode}, {data.city}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary'>
                            Hoofdverblijfplaats
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {data.primaryResidence}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="p" color='textSecondary' >
                            Toestemming
                        </Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.toestemming}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} >
                        <Typography component="p" color='textSecondary' >
                            Opmerkingen
                        </Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.notes}
                        </Typography>
                    </Grid>

                    

                </Grid>

            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(ClientOverview);
