import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { UserData } from '../../data/UserData';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import queryString from 'querystring';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface MigrationFormProps {
    formId: string,
    url: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface MigrationFormErrors {
    [key: string]: any,
    url: string,
}

export interface MigrationFormState {
    url: string,
    errors: MigrationFormErrors
}

type Props = MigrationFormProps & WithStyles<typeof styles>;

class MigrationForm extends React.Component<Props, MigrationFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            url: "",
            errors: {
                url: "",
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<MigrationFormState, keyof MigrationFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        //const { id, firstName, lastName, email, isAdmin, } = this.state;
        //const requestBody = { id, firstName, lastName, email, isAdmin };
        //const queryParams = queryString.stringify(requestBody)
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.url, data).perform();

        if (response.ok) {
            this.props.onSubmitSuccess();
        } else {
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { url, } = this.state;
        let isValid = true;

        currentErrors.url = UtilityString.nullOrEmpty(url) ? "Voer een url in" : '';

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId } = this.props;

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">

                    <TextInput
                        id="url"
                        value={this.state.url}
                        label="Url"
                        name="url"
                        onChange={this.handleChange}
                        required
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MigrationForm));
