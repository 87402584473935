import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface MessageDialogProps {
    open: boolean,
    message: string,
    onClose: () => void,
}

class MessageDialog extends Component<MessageDialogProps> {

    render() {
        const { onClose, open, message} = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Sluiten</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default MessageDialog;