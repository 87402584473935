import AbstractReduxAction from "./AbstractReduxAction";

export default class OnUserInitialized extends AbstractReduxAction
{
    public static readonly TYPE = "on_user_initialized";

    public user: Oidc.User;

    constructor(user: Oidc.User)
    {
        super(OnUserInitialized.TYPE);

        this.user = user;
    }
}
