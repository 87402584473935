import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReduxStoreDefault from './redux/stores/ReduxStoreDefault';
import { Provider } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { unregister } from './registerServiceWorker';
import OnAuthInitialized from './redux/actions/ActionAuthInitialized';
import OnConfigurationLoaded from './redux/actions/ActionConfigurationLoaded';
import { DefaultTheme } from './theme/DefaultTheme';
import Auth from "./auth/Auth";


//Declare the root component.
interface Props {

}

interface State {
    modals: Array<React.Component>,
    loading: boolean
}

//console.log("Life Cycle: Application started.");
class Root extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props);
        this.state =
            {
                modals: [],
                loading: true
            };
    }

    showModal = (modal: React.Component) => {
        var modals = this.state.modals;
        modals.push(modal);

        this.setState({ modals: modals });
    }

    setLoading = (loading: boolean) => {
        this.setState({ loading: loading });
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <p>Loading application, please wait.</p>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Provider store={ReduxStoreDefault.instance.store}>
                        <div>
                            <CssBaseline />
                            <MuiThemeProvider theme={DefaultTheme}>
                                <div id="root-content">
                                    <BrowserRouter basename={"/admin/web"}>
                                        <App />
                                    </BrowserRouter>
                                </div>
                                <div id="root-modals">
                                    {this.state.modals}
                                </div>
                            </MuiThemeProvider>
                        </div>
                    </Provider>
                </div>
            );
        }
    }
}



//Insert the root component into the DOM.
const rootElement = document.getElementById('root');
var RootComponent: Root | null = null;

ReactDOM.render(
    <Root ref={i => RootComponent = i} />,
    rootElement);

unregister();
export default RootComponent;




//Load the hosting configuration from the public endpoint.
fetch("javascript/configuration")
    .then(response => {
        //console.log("Life Cycle: Configuration retrieved.");
        return response.json();
    })
    .then(json => {
        //Signal the Redux Store that the configuration has been loaded.
        ReduxStoreDefault.instance.store.dispatch(new OnConfigurationLoaded(json));

        //Create an Auth object and notify the Redux Store that the Auth object has been initialized.
        var auth = new Auth({
            authority: json.endpoints.kjrwIdentity,
            client_id: "kjrw.admin.web",
            redirect_uri: json.endpoints.kjrwAdminWeb + "/callback/login",
            response_type: "code",
            scope: "openid profile kjrw-admin-api",
            post_logout_redirect_uri: json.endpoints.kjrwAdminWeb + "/"
        });
        ReduxStoreDefault.instance.store.dispatch(new OnAuthInitialized(auth));

        //Initialize the current user, if it exists, and then remove the loading state from the root component.
        auth.initializeUser().then(_ => {
            //console.log("Life Cycle: Authorization initialized.");

            if (RootComponent != null) { RootComponent.setLoading(false); }
        });
    });