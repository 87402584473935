import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CaseAttachmentForm from '../../forms/CaseAttachmentForm';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const addAttachmentFormId = 'form-add-attachment';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface AddCaseAttachmentDialogProps {
    caseId: number,
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
}

type Props = AddCaseAttachmentDialogProps & WithStyles<typeof styles>;

class AddCaseAttachmentDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { classes, onClose, onSuccess, open, caseId } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="dialog-title">Bijlage toevoegen</DialogTitle>
                    <DialogContent>
                        <CaseAttachmentForm
                            formId={addAttachmentFormId}
                            caseId={caseId}
                            apiEndpoint="/caseattachment/addfile"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={addAttachmentFormId}>Toevoegen</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddCaseAttachmentDialog);