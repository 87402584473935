import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const ageOptions = ["4", "5", "6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23", "Onbekend"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
    }
});

export interface AgeSelectProps {
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean
}

type Props = AgeSelectProps & WithStyles<typeof styles>;

function AgeSelect(props: Props) {
    const { classes, onChange, value, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="status-select" required={required}>Leeftijd</InputLabel>
            <Select
                value={value}
                input={<Input name="age" id="age-select" />}
                onChange={onChange}
                displayEmpty
                name='age'
                className={classes.selectEmpty}
                placeholder="Selecteer leeftijd"
            >
                {ageOptions.map(age => (
                    <MenuItem key={age} value={age}>{age}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(AgeSelect);