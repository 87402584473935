import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CaseForm from '../../forms/CaseForm';
import { CaseData } from '../../../data/CaseData';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const editCaseFormId = 'form-add-case';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface EditCaseDialogProps {
    data: CaseData,
    open: boolean,
    onClose: () => void,
    onEditCaseSuccess: () => void,
}

type Props = EditCaseDialogProps & WithStyles<typeof styles>;

class EditCaseDialog extends Component<Props> {

    onSubmitFail = () => {
    }

    render() {
        const { classes, onClose, onEditCaseSuccess, open, data } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={onClose} aria-label="Sluiten"><CloseIcon /></IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>Casus aanpassen</Typography>
                            <Button color="inherit" type="submit" form={editCaseFormId}>Opslaan</Button>
                        </Toolbar>
                    </AppBar>

                    <CaseForm 
                    formId={editCaseFormId} 
                    apiEndpoint="/case/edit" 
                    onSubmitFail={this.onSubmitFail} 
                    onSubmitSuccess={onEditCaseSuccess} 
                    data={data} />

                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditCaseDialog);