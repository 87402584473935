import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserFormEdit from '../../forms/UserFormEdit';
import { UserData } from '../../../data/UserData';

const editUserFormId = 'form-edit-user';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface EditUserDialogProps {
    open: boolean,
    data: UserData,
    onClose: () => void,
    onSuccess: () => void,
}

class EditUserDialog extends Component<EditUserDialogProps> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { onClose, onSuccess, open, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="dialog-title">Gebruiker aanpassen</DialogTitle>
                    <DialogContent>
                        <UserFormEdit
                            formId={editUserFormId}
                            data={data}
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={editUserFormId}>Opslaan</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default EditUserDialog;