import * as React from 'react';
import AddCaseAttachmentDialog from "../../components/dialogs/AddCaseAttachmentDialog";
import { connect } from 'react-redux';
import { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../../theme/Localization';
import ToolbarItemAdd from '../../components/table/ToolbarItemAdd';
import FileTable from '../../components/table/FileTable';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import { DocumentData } from '../../../data/DocumentData';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface CaseAttachmentsProps {
  caseId: number,
  attachments: DocumentData[],
  onDataChanged: () => void,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface CaseAttachmentsState {
  addAttachmentDialogOpen: boolean,
  deleteAttachmentId: number,
  deleteAttachmentDialogOpen: boolean,
}

class CaseAttachments extends React.Component<CaseAttachmentsProps, CaseAttachmentsState> {

  constructor(props: CaseAttachmentsProps) {
    super(props)

    this.state = {
      addAttachmentDialogOpen: false,
      deleteAttachmentId: -1,
      deleteAttachmentDialogOpen: false,
    }
  }

  onAttachmentAddSuccess = () => {
    this.setState({ addAttachmentDialogOpen: false });
    this.props.onDataChanged();
  }

  onDeleteAttachmentConfirm = async (id: number) => {
    const deleteAdditionResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/caseattachment/delete?id=" + id).perform();
    if (deleteAdditionResponse.ok) {
        this.props.onDataChanged();
    } else {
        // TODO: Show snackbar that something went wrong or something like that
    }
    this.setState({ deleteAttachmentDialogOpen: false, deleteAttachmentId: -1 });
  }

  onDelete = (id: number) => {
    this.setState({ deleteAttachmentId: id, deleteAttachmentDialogOpen: true});
  }

  onDownload = async (id: number) => {
    const file = this.props.attachments.find(e => e.id == id)!;
    const response = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/caseattachment/download?id=" + id).perform();

    response.blob().then(blob => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = file.fileName + file.fileType;
      link.click();
    });
  }

  render() {
    const { caseId, attachments } = this.props;
    const { deleteAttachmentId, deleteAttachmentDialogOpen } = this.state;

    const options: MUIDataTableOptions = {
      filter: false,
      search: false,
      responsive: "stacked",
      download: false,
      print: false,
      selectableRows: false,
      textLabels: TableTextLabels,
      rowsPerPageOptions: [5, 10, 20],
      customToolbar: () => {
        return (
          <ToolbarItemAdd
            tooltip="Bijlage toevoegen"
            handleClick={() => this.setState({ addAttachmentDialogOpen: true })}
          />
        );
      },
      elevation: 1,
    };

    return (
      <div>
        <FileTable
          tableTitle="Bijlagen"
          tableOptions={options}
          data={attachments}
          onDelete={this.onDelete}
          onDownload={this.onDownload}
        />

        <AddCaseAttachmentDialog
          caseId={caseId}
          open={this.state.addAttachmentDialogOpen}
          onClose={() => this.setState({ addAttachmentDialogOpen: false })}
          onSuccess={this.onAttachmentAddSuccess}
        />

        {deleteAttachmentId != -1 && (
          <ConfirmDialog
            additionId={deleteAttachmentId}
            title="Bijlage verwijderen?"
            message="Weet u zeker dat u deze bijlage wil verwijderen? Dit kan niet ongedaan worden gemaakt."
            confirmButtonText="Verwijderen"
            open={this.state.deleteAttachmentDialogOpen}
            onClose={() => this.setState({ deleteAttachmentDialogOpen: false })}
            onConfirm={this.onDeleteAttachmentConfirm}
          />
        )}


      </div>
    );
  }
}

export default connect(mapStateToProps)(CaseAttachments);