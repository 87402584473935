import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClientParentData } from '../../../data/ClientParentData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
    textBody: {
        whiteSpace: 'pre-line' as 'pre-line',
    }
};

const editJournalUpdateForm = 'form-edit-journal-update';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface ViewClientParentDialogProps {
    data: ClientParentData,
    open: boolean,
    onClose: () => void,
}

type Props = ViewClientParentDialogProps & WithStyles<typeof styles>;

class ViewClientParentDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: show snackbar or something like that
    }

    render() {
        const { classes, onClose, open, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="dialog-title">{data.name}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={32}>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    Geslacht
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {data.gender}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary' >
                                    Geboortedatum
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.dateOfBirth}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    Heeft gezag
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.authority}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography component="p" color='textSecondary'>
                                    Relatie tot cliënt
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.relationshipToClient == "Overig" ? data.relationshipOther : data.relationshipToClient}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    E-mailadres
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {data.email}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary' >
                                    Telefoonnummer
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.phoneNumber}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    Telefoonnummer (Mobiel)
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.phoneNumberMobile}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography component="p" color='textSecondary'>
                                    Adres
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {data.streetName} {data.houseNumber} <br />
                                    {data.postalCode}, {data.city}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} >
                                <Typography component="p" color='textSecondary'>
                                    Opmerkingen
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.notes}
                                </Typography>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Sluiten</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ViewClientParentDialog);