import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ClientSelectFullName from './Inputs/ClientSelectFullName';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { ClientSiblingData } from '../../data/ClientSiblingData';
import GenderSelect from './Inputs/GenderSelect';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ClientSiblingFormProps {
    formId: string,
    clientId: number,
    apiEndpoint: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    data?: ClientSiblingData,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface ClientSiblingFormErrors {
    [key: string]: any,
    firstName: string,
}

export interface ClientSiblingFormState {
    id: number,
    clientSiblingId: number, // Quite confusing but this is the value relating the sibling to the client as it is required by the server.
    firstName: string,
    lastName: string,
    lastNamePrefix: string,
    gender: string,
    age: string,
    clientId: string, // This is the value in case the sibling is also a client and it is selected. I know, its retarded but I am a retard so deal with it.
    errors: ClientSiblingFormErrors,
}

type Props = ClientSiblingFormProps & WithStyles<typeof styles>;

class ClientSiblingForm extends React.Component<Props, ClientSiblingFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            id: props.data ? props.data!.id : -1,
            clientSiblingId: props.clientId,
            firstName: props.data && !props.data!.isClient ? props.data!.firstName : "",
            lastName: props.data && !props.data!.isClient ? props.data!.lastName : "",
            lastNamePrefix: props.data && !props.data!.isClient ? props.data!.lastNamePrefix : "",
            gender: props.data && !props.data!.isClient ? props.data!.gender : "",
            age: props.data && !props.data!.isClient ? props.data!.age : "",
            clientId: props.data && props.data!.isClient ? props.data!.clientId.toString() : "",
            errors: {
                firstName: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<ClientSiblingFormState, keyof ClientSiblingFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.apiEndpoint, data).perform();

        if (response.ok) {
            console.log('success in form')
            this.props.onSubmitSuccess();
        } else {
            console.log('Failed in form')
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { firstName, clientId } = this.state;
        let isValid = true;

        currentErrors.firstName = UtilityString.nullOrEmpty(clientId) && UtilityString.nullOrEmpty(firstName) ? "Voornaam is verplicht als er geen cliënt geselecteerd is" : '';

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId, clientId, data } = this.props;

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    {data != undefined &&
                        <React.Fragment>
                            <Input id="Id" name="Id" value={data!.id} className={classes.hidden} />
                        </React.Fragment>
                    }
                    <Input id="clientSiblingId" name="clientSiblingId" value={clientId} className={classes.hidden} />

                    <ClientSelectFullName
                        onChange={this.handleChange}
                        excludedClientId={clientId}
                        label="Broer/zus als cliënt"
                        name="clientId"
                        value={this.state.clientId}
                        error={this.state.errors.secondVolunteerId} />

                    <TextInput
                        id="firstName-text"
                        value={this.state.firstName}
                        label="Voornaam"
                        name="firstName"
                        onChange={this.handleChange}
                        error={this.state.errors.firstName}
                        required={UtilityString.nullOrEmpty(this.state.clientId)}
                    />

                    <TextInput
                        id="lastNamePrefix-text"
                        value={this.state.lastNamePrefix}
                        label="Tussenvoegsel"
                        name="lastNamePrefix"
                        onChange={this.handleChange}
                    />

                    <TextInput
                        id="lastName-text"
                        value={this.state.lastName}
                        label="Achternaam"
                        name="lastName"
                        onChange={this.handleChange}
                    />

                    <GenderSelect
                        value={this.state.gender}
                        onChange={this.handleChange} />

                    <TextInput
                        id="age-text"
                        value={this.state.age}
                        label="Leeftijd"
                        name="age"
                        onChange={this.handleChange}
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ClientSiblingForm));
