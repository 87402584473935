import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { UserData } from '../../data/UserData';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import queryString from 'querystring';
import UtilityRole from "../../utilities/UtilityRole";
import AuthRole from '../../auth/AuthRole';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface UserFormProps {
    formId: string,
    onSubmitSuccess: (password:string) => void,
    onSubmitFail: () => void,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface UserFormErrors {
    [key: string]: any,
    firstName: string,
    lastName: string,
    email: string,
}

export interface UserFormState {
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean,
    errors: UserFormErrors
}

type Props = UserFormProps & WithStyles<typeof styles>;

class UserFormAdd extends React.Component<Props, UserFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            firstName:'',
            lastName: '',
            email: '',
            isAdmin: false,
            errors: {
                firstName: '',
                lastName: '',
                email: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<UserFormState, keyof UserFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        //console.log(this.state);

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }
        
        const { firstName, lastName, email, isAdmin, } = this.state;
        const requestBody = { email, isAdmin };
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwIdentity + "/account/createuser?" + queryString.stringify(requestBody)).perform();

        if (response.ok) {
            response.json().then(async data => {
                const {id, password, roles} = data;
                const addApiUserRequestBody = {id, email, firstName, lastName, roles};
                const addApiUserResponse: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/user/add?" + queryString.stringify(addApiUserRequestBody)).perform();

                if(addApiUserResponse.ok) {
                    this.props.onSubmitSuccess(password);
                } else {
                    this.props.onSubmitFail();
                }
            })
        } else {
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { firstName, lastName, email } = this.state;
        let isValid = true;

        currentErrors.firstName = UtilityString.nullOrEmpty(firstName) ? "Voornaam is verplicht" : '';
        currentErrors.lastName = UtilityString.nullOrEmpty(lastName) ? "Achternaam is verplicht" : '';

        // Email
        const regxEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
        if (UtilityString.nullOrEmpty(email)) {
            currentErrors.email = "E-mailadres is verplicht";
        } else {
            currentErrors.email = !regxEmail.test(email) ? "Voer een geldig e-mailadres in" : "";
        }

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId } = this.props;

        const showId = UtilityRole.hasRole(this.props.user, AuthRole.DEVELOPER);

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">

                    <Input id="isAdmin" name="isAdmin" value={this.state.isAdmin} className={classes.hidden} />

                    <TextInput
                        id="firstName"
                        value={this.state.firstName}
                        label="Voornaam"
                        name="firstName"
                        onChange={this.handleChange}
                        error={this.state.errors.firstName}
                        required
                    />

                    <TextInput
                        id="lastName"
                        value={this.state.lastName}
                        label="Achternaam"
                        name="lastName"
                        onChange={this.handleChange}
                        error={this.state.errors.lastName}
                        required
                    />

                    <TextInput
                        id="email"
                        value={this.state.email}
                        label="E-mailadres"
                        name="email"
                        onChange={this.handleChange}
                        error={this.state.errors.email}
                        required
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isAdmin"
                                id="isAdmin"
                                checked={this.state.isAdmin}
                                onChange={(event: any, checked: boolean) => this.setState({ isAdmin: checked })}
                            />
                        }
                        label="Administrator"
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserFormAdd));
