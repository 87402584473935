import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ClientForm from '../../forms/ClientForm';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const addClientFormId = 'form-add-client';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface AddClientDialogProps {
    open: boolean,
    onClose: () => void,
    onAddClientSuccess: () => void,
}

type Props = AddClientDialogProps & WithStyles<typeof styles>;

class AddClientDialog extends Component<Props> {

    onSubmitFail = () => {
    }

    render() {
        const { classes, onClose, onAddClientSuccess, open } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={onClose} aria-label="Sluiten">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Cliënt toevoegen
                            </Typography>
                            <Button color="inherit" type="submit" form={addClientFormId}>
                                Toevoegen
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <ClientForm
                        formId={addClientFormId}
                        apiEndpoint="/client/add"
                        onSubmitFail={this.onSubmitFail}
                        onSubmitSuccess={onAddClientSuccess} />

                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddClientDialog);