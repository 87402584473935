import { createStore, Store, Action, applyMiddleware} from 'redux';
import InitialReducer  from '../reducers/ReducerInitial';
import ReduxState from '../ReduxState';
import MiddlewareClassConversion from "../middleware/MiddlewareClassConversion";

export default class ReduxStoreDefault 
{
    public static readonly instance = new ReduxStoreDefault();

    public readonly store: Store<ReduxState, Action<any>>

    private constructor()
    {
        this.store = createStore(InitialReducer, applyMiddleware(MiddlewareClassConversion));
    }
}
