export default class HttpMethod
{
    public static readonly GET = new HttpMethod("GET");
    public static readonly POST = new HttpMethod("POST");
    public static readonly DELETE = new HttpMethod("DELETE");
    public static readonly PUT = new HttpMethod("PUT");

    public readonly name: string;

    private constructor(name: string)
    {
        this.name = name;
    }
}