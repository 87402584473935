export default class UtilityString
{
    static nullOrEmpty = (value: string) =>
    {   return value == null || value == ''}

    static parseDateToYYYYMMDD = (value: string) => {
        var m = value.match(/^(\d{1,2})(\/|-)?(\d{1,2})(\/|-)?(\d{4})$/);
        if (m)
            value = m[5] + '-' + ("00" + m[3]).slice(-2) + '-' + ("00" + m[1]).slice(-2);
    
        return value;
    }
}