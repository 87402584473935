import * as React from 'react';
import { connect } from 'react-redux';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../../theme/Localization';
import ToolbarItemAdd from '../../components/table/ToolbarItemAdd';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Create';
import { ClientParentData } from '../../../data/ClientParentData';
import AddClientParentDialog from '../../components/dialogs/AddClientParentDialog';
import EditClientParentDialog from '../../components/dialogs/EditClientParentDialog';
import ViewClientParentDialog from '../../components/dialogs/ViewClientParentDialog';
import ViewIcon from "@material-ui/icons/Fullscreen";

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface ClientParentsProps {
  clientId: number,
  parents: ClientParentData[],
  onDataChanged: () => void,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface ClientParentsState {
  addParentDialogOpen: boolean,
  deleteParentId: number,
  deleteParentDialogOpen: boolean,
  editParentId: number,
  editParentDialogOpen: boolean,
  viewParentId: number,
  viewParentDialogOpen: boolean,
}

class ClientParents extends React.Component<ClientParentsProps, ClientParentsState> {

  constructor(props: ClientParentsProps) {
    super(props)

    this.state = {
      addParentDialogOpen: false,
      deleteParentId: -1,
      deleteParentDialogOpen: false,
      editParentId: -1,
      editParentDialogOpen: false,
      viewParentId: -1,
      viewParentDialogOpen: false,
    }
  }

  onParentAddSuccess = () => {
    this.setState({ addParentDialogOpen: false });
    this.props.onDataChanged();
  }

  onParentEditSucces = () => {
    this.setState({ editParentDialogOpen: false, editParentId: -1 });
    this.props.onDataChanged();
  }

  onDeleteParentConfirm = async (id: number) => {
    const deleteAdditionResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/parent/delete?id=" + id).perform();
    if (deleteAdditionResponse.ok) {
      this.props.onDataChanged();
    } else {
      // TODO: Show snackbar that something went wrong or something like that
    }
    this.setState({ deleteParentDialogOpen: false, deleteParentId: -1 });
  }

  onView = (id: number) => {
    this.setState({ viewParentId: id, viewParentDialogOpen: true });
  }

  onDelete = (id: number) => {
    this.setState({ deleteParentId: id, deleteParentDialogOpen: true });
  }

  onEdit = (id: number) => {
    this.setState({ editParentId: id, editParentDialogOpen: true });
  }

  render() {
    const { clientId, parents } = this.props;
    const { editParentId, editParentDialogOpen, deleteParentId, deleteParentDialogOpen, viewParentId, viewParentDialogOpen } = this.state;

    const columns = [
      {
        name: "Id",
        label: "ID",
        options: {
          display: 'excluded' as 'excluded',
        }
      },
      {
        name: "Name",
        label: "Naam",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "RelationshipToClient",
        label: "Relatie tot cliënt",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "Authority",
        label: "Gezag",
        options: {
          filter: true,
          sort: true,
        }
      }, {
        name: "actions",
        label: " ",
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
              <React.Fragment>
                <Tooltip title={"Vergroten"}>
                  <IconButton onClick={() => this.onView(tableMeta.rowData[0])}>
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Aanpassen"}>
                  <IconButton onClick={() => this.onEdit(tableMeta.rowData[0])}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Verwijderen"}>
                  <IconButton onClick={() => this.onDelete(tableMeta.rowData[0])}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            );
          },
          setCellProps: () => {
            return {
              align: 'right',
            }
          }
        },
      },
    ];

    const options: MUIDataTableOptions = {
      filter: false,
      search: false,
      responsive: "scroll",
      download: false,
      print: false,
      selectableRows: false,
      textLabels: TableTextLabels,
      rowsPerPageOptions: [5, 10, 20],
      customToolbar: () => {
        return (
          <ToolbarItemAdd
            tooltip="Ouder toevoegen"
            handleClick={() => this.setState({ addParentDialogOpen: true })}
          />
        );
      },
      elevation: 1,
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={"Ouders"}
          data={parents.map(s => [s.id, s.name, s.relationshipToClient, s.authority])}
          columns={columns}
          options={options}
        />

        <AddClientParentDialog
          clientId={this.props.clientId}
          open={this.state.addParentDialogOpen}
          onClose={() => this.setState({ addParentDialogOpen: false })}
          onSuccess={this.onParentAddSuccess}
        />

        {viewParentId != -1 && (
          <ViewClientParentDialog
            open={viewParentDialogOpen}
            data={this.props.parents.find(p => p.id == viewParentId)!}
            onClose={() => this.setState({ viewParentDialogOpen: false, viewParentId: -1 })}
          />
        )}

        {editParentId != -1 && (
          <EditClientParentDialog
            clientId={this.props.clientId}
            open={this.state.editParentDialogOpen}
            data={this.props.parents.find(p => p.id == editParentId)!}
            onClose={() => this.setState({ editParentDialogOpen: false, editParentId: -1 })}
            onSuccess={this.onParentEditSucces}
          />
        )}

        {deleteParentId != -1 && (
          <ConfirmDialog
            additionId={deleteParentId}
            title="Ouder verwijderen?"
            message="Weet u zeker dat u deze ouder wil verwijderen? Dit kan niet ongedaan worden gemaakt."
            confirmButtonText="Verwijderen"
            open={this.state.deleteParentDialogOpen}
            onClose={() => this.setState({ deleteParentDialogOpen: false })}
            onConfirm={this.onDeleteParentConfirm}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ClientParents);