import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CaseAdditionForm from '../../forms/CaseAdditionForm';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const addAdditionFormId = 'form-add-addition';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface AddCaseAdditionDialogProps {
    caseId: number,
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
}

class AddCaseAdditionDialog extends Component<AddCaseAdditionDialogProps> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { onClose, onSuccess, open, caseId } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="dialog-title">Update toevoegen</DialogTitle>
                    <DialogContent>
                        <CaseAdditionForm
                            formId={addAdditionFormId}
                            caseId={caseId}
                            apiEndpoint="/caseaddition/add"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={addAdditionFormId}>Toevoegen</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AddCaseAdditionDialog;