import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClientParentForm from '../../forms/ClientParentForm';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const addParentFormId = 'form-add-parent';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface AddClientParentDialogProps {
    clientId: number,
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
}

type Props = AddClientParentDialogProps & WithStyles<typeof styles>;

class AddClientParentDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { classes, onClose, onSuccess, open, clientId } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullScreen
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={onClose} aria-label="Sluiten">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Ouder toevoegen
                            </Typography>
                            <Button color="inherit" type="submit" form={addParentFormId}>
                                Toevoegen
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <ClientParentForm
                        formId={addParentFormId}
                        clientId={clientId}
                        apiEndpoint="/parent/add"
                        onSubmitFail={this.onSubmitFail}
                        onSubmitSuccess={onSuccess} />
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddClientParentDialog);