import redux from 'redux';
import OnUserInitialized from '../redux/actions/ActionUserInitialized';
import Oidc from 'oidc-client';
import AuthRole from './AuthRole';
import ReduxStoreDefault from '../redux/stores/ReduxStoreDefault';

export default class Auth 
{
    manager: Oidc.UserManager;

    constructor(config: Oidc.UserManagerSettings)
    {
        this.manager = new Oidc.UserManager(config);
    }

    initializeUser = () => 
    {
        return this.manager.getUser().then(function (user)
        {
            ReduxStoreDefault.instance.store.dispatch(new OnUserInitialized(user));
            return user;
        });
    }

    public userHasRole = (targetRole: AuthRole) =>
    {
        var user = this.getUser();
        if (user && user.profile && user.profile.role)
        {
            for (var i = 0; i < user.profile.role.length; i++)
            {
                var currentRole = user.profile.role[i];
                if (currentRole === targetRole)
                {   return true; }
            }
        }

        return false;
    }

    public userIsLoggedIn = () =>
    {
        var user = this.getUser();
        if (user && user.expires_at)
        {
            var now = new Date().getTime() / 1000;
            return user.expires_at > now;
        }

        return false;
    }

    public getUser()  
    {
        return ReduxStoreDefault.instance.store.getState().user;
    }

    public login = () => 
    {
        this.manager.signinRedirect();
    }

    public logout = () => 
    {
        this.manager.signoutRedirect();
    }
}