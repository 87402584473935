import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { UserData } from '../../data/UserData';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import queryString from 'querystring';
import UtilityRole from "../../utilities/UtilityRole";
import AuthRole from '../../auth/AuthRole';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface UserFormProps {
    formId: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    data?: UserData,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface UserFormErrors {
    [key: string]: any,
    firstName: string,
    lastName: string,
    email: string,
}

export interface UserFormState {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean,
    errors: UserFormErrors
}

type Props = UserFormProps & WithStyles<typeof styles>;

class UserFormEdit extends React.Component<Props, UserFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            id: props.data ? props.data!.id : "",
            firstName: props.data ? props.data!.firstName : '',
            lastName: props.data ? props.data!.lastName : '',
            email: props.data ? props.data!.email : '',
            isAdmin: props.data ? props.data!.roles.includes("administrator") : false,
            errors: {
                firstName: '',
                lastName: '',
                email: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<UserFormState, keyof UserFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        //console.log(this.state);

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const { id, firstName, lastName } = this.state;
        const requestBody = { id, firstName, lastName};
        const queryParams = queryString.stringify(requestBody)
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/user/edituser?" + queryParams).perform();

        if (response.ok) {
            this.props.onSubmitSuccess();
        } else {
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { firstName, lastName, email } = this.state;
        let isValid = true;

        currentErrors.firstName = UtilityString.nullOrEmpty(firstName) ? "Voornaam is verplicht" : '';
        currentErrors.lastName = UtilityString.nullOrEmpty(lastName) ? "Achternaam is verplicht" : '';

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId, data } = this.props;

        const showId = UtilityRole.hasRole(this.props.user, AuthRole.DEVELOPER);

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    {data != undefined &&                        
                        <React.Fragment>
                            {showId &&                            
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel htmlFor="Id">UserId (Developer only)</InputLabel>
                                    <Input id="Id" name="Id" value={data!.id} readOnly />
                                </FormControl>
                            }
                            {!showId &&
                                <Input id="Id" name="Id" value={data!.id} className={classes.hidden} />
                            }
                        </React.Fragment>
                    }

                    <Input id="isAdmin" name="isAdmin" value={this.state.isAdmin} className={classes.hidden} />

                    <TextInput
                        id="firstName"
                        value={this.state.firstName}
                        label="Voornaam"
                        name="firstName"
                        onChange={this.handleChange}
                        error={this.state.errors.firstName}
                        required
                    />

                    <TextInput
                        id="lastName"
                        value={this.state.lastName}
                        label="Achternaam"
                        name="lastName"
                        onChange={this.handleChange}
                        error={this.state.errors.lastName}
                        required
                    />

                    <TextInput
                        id="email"
                        value={this.state.email}
                        label="E-mailadres"
                        name="email"
                        onChange={this.handleChange}
                        error={this.state.errors.email}
                        required
                        disabled
                    />

                    <FormControlLabel disabled
                        control={
                            <Checkbox
                                name="isAdmin"
                                id="isAdmin"
                                checked={this.state.isAdmin}
                                onChange={(event: any, checked: boolean) => this.setState({ isAdmin: checked })}
                            />
                        }
                        label="Administrator"
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserFormEdit));
