import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClientSiblingForm from '../../forms/ClientSiblingForm';
import { ClientSiblingData } from '../../../data/ClientSiblingData';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const addSiblingFormId = 'form-edit-sibling';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface EditClientSiblingDialogProps {
    clientId: number,
    open: boolean,
    data: ClientSiblingData,
    onClose: () => void,
    onSuccess: () => void,
}

type Props = EditClientSiblingDialogProps & WithStyles<typeof styles>;

class EditClientSiblingDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { classes, onClose, onSuccess, open, clientId, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="dialog-title">Broer/zus aanpassen</DialogTitle>
                    <DialogContent>
                        <ClientSiblingForm
                            formId={addSiblingFormId}
                            clientId={clientId}
                            data={data}
                            apiEndpoint="/sibling/edit"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={addSiblingFormId}>Opslaan</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditClientSiblingDialog);