import * as React from 'react';
import { connect } from 'react-redux';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../../theme/Localization';
import ToolbarItemAdd from '../../components/table/ToolbarItemAdd';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import AddClientSiblingDialog from '../../components/dialogs/AddClientSiblingDialog';
import EditClientSiblingDialog from '../../components/dialogs/EditClientSiblingDialog';
import { ClientSiblingData } from '../../../data/ClientSiblingData';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Create';

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface ClientSiblingsProps {
  clientId: number,
  siblings: ClientSiblingData[],
  onDataChanged: () => void,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface ClientSiblingsState {
  addSiblingDialogOpen: boolean,
  deleteSiblingId: number,
  deleteSiblingDialogOpen: boolean,
  editSiblingId: number,
  editSiblingDialogOpen: boolean,
}

class ClientSiblings extends React.Component<ClientSiblingsProps, ClientSiblingsState> {

  constructor(props: ClientSiblingsProps) {
    super(props)

    this.state = {
      addSiblingDialogOpen: false,
      deleteSiblingId: -1,
      deleteSiblingDialogOpen: false,
      editSiblingId: -1,
      editSiblingDialogOpen: false,
    }
  }

  onSiblingAddSuccess = () => {
    this.setState({ addSiblingDialogOpen: false });
    this.props.onDataChanged();
  }

  onSiblingEditSuccess = () => {
    this.setState({ editSiblingDialogOpen: false, editSiblingId: -1 });
    this.props.onDataChanged();
  }

  onDeleteSiblingConfirm = async (id: number) => {
    const deleteAdditionResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/sibling/delete?id=" + id).perform();
    if (deleteAdditionResponse.ok) {
      this.props.onDataChanged();
    } else {
      // TODO: Show snackbar that something went wrong or something like that
    }
    this.setState({ deleteSiblingDialogOpen: false, deleteSiblingId: -1 });
  }

  onDelete = (id: number) => {
    this.setState({ deleteSiblingId: id, deleteSiblingDialogOpen: true });
  }

  onEdit = (id: number) => {
    this.setState({ editSiblingId: id, editSiblingDialogOpen: true });
  }

  render() {
    const { clientId, siblings } = this.props;
    const { deleteSiblingId, editSiblingId } = this.state;

    const columns = [
      {
        name: "Id",
        label: "ID",
        options: {
          display: 'excluded' as 'excluded',
        }
      },
      {
        name: "Name",
        label: "Naam",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "Gender",
        label: "Geslacht",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "Age",
        label: "Leeftijd",
        options: {
          filter: true,
          sort: true,
        }
      }, {
        name: "actions",
        label: " ",
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
              <React.Fragment>
                <Tooltip title={"Aanpassen"}>
                  <IconButton onClick={() => this.onEdit(tableMeta.rowData[0])}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Verwijderen"}>
                  <IconButton onClick={() => this.onDelete(tableMeta.rowData[0])}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            );
          },
          setCellProps: () => {
            return {
              align: 'right',
            }
          }
        },
      },
    ];

    const options: MUIDataTableOptions = {
      filter: false,
      search: false,
      responsive: "scroll",
      download: false,
      print: false,
      selectableRows: false,
      textLabels: TableTextLabels,
      rowsPerPageOptions: [5, 10, 20],
      customToolbar: () => {
        return (
          <ToolbarItemAdd
            tooltip="Broer/zus toevoegen"
            handleClick={() => this.setState({ addSiblingDialogOpen: true })}
          />
        );
      },
      elevation: 1,
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={"Broers en zussen"}
          data={siblings.map(s => [s.id, s.name, s.gender, s.age])}
          columns={columns}
          options={options}
        />

        <AddClientSiblingDialog
          clientId={this.props.clientId}
          open={this.state.addSiblingDialogOpen}
          onClose={() => this.setState({ addSiblingDialogOpen: false })}
          onSuccess={this.onSiblingAddSuccess}
        />

        {editSiblingId != -1 && (
          <EditClientSiblingDialog
            clientId={this.props.clientId}
            open={this.state.editSiblingDialogOpen}
            data={this.props.siblings.find(s => s.id == editSiblingId)!}
            onClose={() => this.setState({ editSiblingDialogOpen: false, editSiblingId: -1 })}
            onSuccess={this.onSiblingEditSuccess}
          />
        )}

        {deleteSiblingId != -1 && (
          <ConfirmDialog
            additionId={deleteSiblingId}
            title="Broer/zus verwijderen?"
            message="Weet u zeker dat u deze broer/zus wil verwijderen? Dit kan niet ongedaan worden gemaakt."
            confirmButtonText="Verwijderen"
            open={this.state.deleteSiblingDialogOpen}
            onClose={() => this.setState({ deleteSiblingDialogOpen: false, deleteSiblingId: -1 })}
            onConfirm={this.onDeleteSiblingConfirm}
          />
        )}

      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ClientSiblings);