import { createMuiTheme } from '@material-ui/core/styles';

export const DefaultTheme = createMuiTheme ({
    palette:
    {
        primary:
        {
            light: "#ff6434",
            main: "#dd2c00",
            dark: "#a30000",
            contrastText: '#FFFFFF'
        },
        secondary:
        {
            light: "#83b9ff",
            main: "#448aff",
            dark: "#005ecb",
            contrastText: '#FFFFFF'
        }        
    },
    overrides:
        {
            MuiButton:
                {
                    label:
                        {
                            paddingTop: '0px'
                        }
                }
        }

});

