import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import MigrationForm from '../../forms/MigrationForm';

const changePasswordFormId = 'import-data-form';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface ImportAttachmentsDialogProps {
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
}

class ImportAttachmentsDialog extends Component<ImportAttachmentsDialogProps> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { onClose, onSuccess, open } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="dialog-title">Import Attachments</DialogTitle>
                    <DialogContent>
                        <MigrationForm
                            formId={changePasswordFormId}
                            url="/migration/importcaseattachments"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={changePasswordFormId}>Versturen</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ImportAttachmentsDialog;