import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ClientSelectFullNameState {
    options: { id: number, name: string }[],
}

export interface ClientSelectFullNameProps {
    onChange: (event: any) => void,
    excludedClientId?: number,
    label: string,
    name: string,
    value: string,
    error?: string,
    required?: boolean, 
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

type Props = ClientSelectFullNameProps & WithStyles<typeof styles>;

class ClientSelectFullName extends React.Component<Props, ClientSelectFullNameState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            options: [],
        };
    }

    componentDidMount = async () => {
        let actionCall = "/client/getallfullname";

        if(this.props.excludedClientId) {
            actionCall = actionCall + "?excludedClientId=" + this.props.excludedClientId!;
        }

        const getClients = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + actionCall).perform();
        await getClients.json().then(data => {
            this.setState({ options: data });
        });
    }

    public render() {
        const { classes, onChange, value, error, required, label, name } = this.props;
        const { options } = this.state;

        return (
            <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
                <InputLabel htmlFor="client-select" required={required}>{label}</InputLabel>
                <Select
                    value={value}
                    input={<Input name={name} id="client-select"/>}
                    onChange={onChange}
                    displayEmpty={required}
                    name={name}
                    className={classes.selectEmpty}
                    placeholder="Kies een cliënt"
                >
                {!required && <MenuItem value=""><em>Geen</em></MenuItem>}

                    {options.map(option =>
                        <MenuItem key={option.id} value={option.id}>{option.id} - {option.name}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ClientSelectFullName));