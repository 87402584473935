import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme: Theme) => ({
    formControl: {
        margin: theme.spacing.unit,
    }
});

export interface TextInputProps {
    id: string,
    name: string,
    label: string,
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean,
    multiline?: boolean,
    disabled?: boolean,
    rows?: string,
    type?: string,
    defaultValue? : string | number | boolean | object | (string | number | boolean | object)[] | undefined,
}

type Props = TextInputProps & WithStyles<typeof styles>;

function TextInput(props: Props) {
    const { classes, id, name, label, onChange, value, error, required, multiline, disabled, rows, type, defaultValue} = props;
    const helperId = id + "-helper";
    return (
        <FormControl className={classes.formControl} fullWidth disabled={disabled} error={error != null && error != ''}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
        <Input
            id={id}
            value={value}
            name={name}
            multiline={multiline}            
            rows={rows}
            rowsMax={500}            
            onChange={onChange}
            aria-describedby={helperId}
            defaultValue={defaultValue}
            type={type != null ? type : 'text'}
        />
        <FormHelperText id={helperId}>{error}</FormHelperText>
    </FormControl>
    );
}

export default withStyles(styles)(TextInput);