import ReduxConfigurationEntity from "./entities/ReduxConfigurationEntity";
import Auth from "../auth/Auth";

export default class ReduxState
{
    configuration: ReduxConfigurationEntity | undefined;
    auth: Auth | undefined;
    user: Oidc.User | undefined;

    constructor()
    {

    }
}

