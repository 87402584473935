import * as React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../theme/Localization';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import AddJournalUpdateDialog from "../components/dialogs/AddJournalUpdateDialog";
import EditJournalUpdateDialog from "../components/dialogs/EditJournalUpdateDialog";
import ViewJournalUpdateDialog from "../components/dialogs/ViewJournalUpdateDialog";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import ToolbarItemAdd from '../components/table/ToolbarItemAdd';
import { titleOptions } from '../forms/Inputs/TitleSelect';
import { stateOptions } from '../forms/Inputs/StatusSelect';
import UtilityString from '../../utilities/UtilityString';
import queryString from 'querystring';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Fullscreen";
import { JournalUpdateData } from '../../data/JournalUpdateData';

const columns = [
    {
        name: "Id",
        label: "ID",
        options: {
            filter: true,
            sort: false,
            sortDirection: 'desc' as 'desc'
        }
    },
    {
        name: "Subject",
        label: "Onderwerp",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "Timestamp",
        label: "Aanmaakdatum",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "Volunteer",
        label: "Aangemaakt door",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                return (<React.Fragment />);
            },
            setCellProps: () => {
                return {
                    align: 'right',
                }
            }
        },
    },
];

const allowedTableChangedActions = ["filterChange", "resetFilters", "changePage", "changeRowsPerPage", "sort", "search"];

const styles = (theme: Theme) => ({
    fab: {
        margin: theme.spacing.unit,
        position: 'fixed' as 'fixed',
        right: 20,
        bottom: 20,
    },
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface JournalProps {
    history: History,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface JournalState {
    data: JournalUpdateData[],
    rowsPerPage: number,
    totalRowCount: number,
    addUpdateDialogOpen: boolean,
    editUpdateDialogOpen: boolean,
    editUpdateId: number,
    deleteUpdateDialogOpen: boolean,
    deleteUpdateId: number,
    viewUpdateDialogOpen: boolean,
    viewUpdateId: number,
}

type Props = JournalProps & WithStyles<typeof styles>;

class Journal extends React.Component<Props, JournalState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            rowsPerPage: 10,
            totalRowCount: 0,
            addUpdateDialogOpen: false,
            editUpdateDialogOpen: false,
            editUpdateId: -1,
            deleteUpdateDialogOpen: false,
            deleteUpdateId: -1,
            viewUpdateDialogOpen: false,
            viewUpdateId: -1,
        };
    }

    componentDidMount = () => {
        const { rowsPerPage } = this.state;
        this.loadData(columns[0].name, 'desc', [], 0, rowsPerPage, '');
    }

    loadData = async (sortedColumn: string, sortDirection: string, columnFilters: string[], page: number, rowsPerPage: number, searchText: string) => {
        const requestBody = { sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText };
        const queryParams = queryString.stringify(requestBody)

        const getJournal = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/journal/getallminified?" + queryParams).perform();
        await getJournal.json().then(data => this.onDataReceived(data));
    }

    onRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
        //this.props.history.push("/case/" + rowData[0]);
    }

    onTableChange = async (action: string, tableState: any) => {

        if (!allowedTableChangedActions.includes(action)) {
            return;
        }

        let sortedColumn: string = columns[0].name;
        let sortDirection: string = 'desc';
        let columnFilters: string[] = [];
        let page: number = tableState.page;
        let rowsPerPage: number = tableState.rowsPerPage;
        let searchText: string = tableState.searchText;

        if (tableState.activeColumn) {
            sortedColumn = tableState.columns[tableState.activeColumn].name;
            sortDirection = tableState.columns[tableState.activeColumn].sortDirection;
        }

        for (let i = 0; i < tableState.columns.length; i++) {
            const columnObject: any = tableState.columns[i];
            const filterValue = tableState.filterList[i][0];
            if (!UtilityString.nullOrEmpty(filterValue)) {
                columnFilters.push(columnObject.name + "," + filterValue);
            }
        }

        this.loadData(sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText);
    }

    onDataReceived = (receivedData: any) => {
        let rowsPerPage = receivedData.rowsPerPage;
        let totalRowCount = receivedData.totalRowCount;
        let receivedDataEntries: [any] = receivedData.data;
        this.setState({ data: receivedDataEntries, totalRowCount, rowsPerPage });
    }

    onAddUpdateSuccess = () => {
        this.setState({ addUpdateDialogOpen: false })
        // Probably should just add the single case but this is easier when filters/searchqueries and sorting is used.
        // Should look into keeping the table state, although resetting the table state would make sense if you alter the data in it.
        this.loadData(columns[0].name, 'desc', [], 0, this.state.rowsPerPage, '');
    }

    onUpdateEditSucces = () => {
        this.setState({ editUpdateDialogOpen: false, editUpdateId: -1 });
        this.componentDidMount();
    }

    onDeleteUpdateConfirm = async (id: number) => {
        const deleteUpdate = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/journal/delete?id=" + id).perform();
        if (deleteUpdate.ok) {
            this.componentDidMount();
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ deleteUpdateDialogOpen: false, deleteUpdateId: -1 });
    }

    openAddUpdateDialog = () => {
        this.setState({ addUpdateDialogOpen: true });
    }

    onView = (updateId: number) => {
        this.setState({ viewUpdateId: updateId, viewUpdateDialogOpen: true });
    }

    onDelete = (updateId: number) => {
        this.setState({ deleteUpdateId: updateId, deleteUpdateDialogOpen: true });
    }

    onEdit = (updateId: number) => {
        this.setState({ editUpdateId: updateId, editUpdateDialogOpen: true });
    }

    renderActionColBody = (value: any, tableMeta: any, updateValue: any) => {
        return (
            <React.Fragment>
                <Tooltip title={"Vergroten"}>
                    <IconButton onClick={() => this.onView(tableMeta.rowData[0])}>
                        <ViewIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Aanpassen"}>
                    <IconButton onClick={() => this.onEdit(tableMeta.rowData[0])}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Verwijderen"}>
                    <IconButton onClick={() => this.onDelete(tableMeta.rowData[0])}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment >
        );
    }

    public render() {
        const { classes } = this.props;
        const { data, rowsPerPage, totalRowCount, addUpdateDialogOpen, editUpdateDialogOpen, editUpdateId, deleteUpdateDialogOpen, deleteUpdateId, viewUpdateDialogOpen, viewUpdateId } = this.state;

        // Have to set this here as there is no context in the global scope and the clicks require it.
        columns[4].options.customBodyRender = this.renderActionColBody

        const options: MUIDataTableOptions = {
            filter: false,
            filterType: "dropdown",
            responsive: "stacked",
            download: false,
            print: false,
            selectableRows: false,
            onRowClick: this.onRowClick,
            textLabels: TableTextLabels,
            count: totalRowCount,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
            customToolbar: () => {
                return (
                    <ToolbarItemAdd
                        tooltip="Update toevoegen"
                        handleClick={this.openAddUpdateDialog}
                    />
                );
            },
            serverSide: true,
            onTableChange: this.onTableChange,
        };

        return (
            <div>
                <React.Fragment>
                    <MUIDataTable
                        title={"Logboek updates"}
                        data={data.map(e => [e.id, e.subject, e.timestamp, e.volunteer.name])}
                        columns={columns}
                        options={options}
                    />

                    <AddJournalUpdateDialog
                        open={addUpdateDialogOpen}
                        onClose={() => this.setState({ addUpdateDialogOpen: false })}
                        onSuccess={this.onAddUpdateSuccess}
                    />

                    {viewUpdateId != -1 && (
                        <ViewJournalUpdateDialog
                            open={viewUpdateDialogOpen}
                            data={this.state.data.find(e => e.id == viewUpdateId)!}
                            onClose={() => this.setState({ viewUpdateDialogOpen: false, viewUpdateId: -1 })}
                        />
                    )}

                    {editUpdateId != -1 && (
                        <EditJournalUpdateDialog
                            open={editUpdateDialogOpen}
                            data={this.state.data.find(e => e.id == editUpdateId)!}
                            onClose={() => this.setState({ editUpdateDialogOpen: false, editUpdateId: -1 })}
                            onSuccess={this.onUpdateEditSucces}
                        />
                    )}

                    {deleteUpdateId != -1 && (
                        <ConfirmDialog
                            additionId={deleteUpdateId}
                            title="Ouder verwijderen?"
                            message="Weet u zeker dat u deze ouder wil verwijderen? Dit kan niet ongedaan worden gemaakt."
                            confirmButtonText="Verwijderen"
                            open={deleteUpdateDialogOpen}
                            onClose={() => this.setState({ deleteUpdateDialogOpen: false })}
                            onConfirm={this.onDeleteUpdateConfirm}
                        />
                    )}

                </React.Fragment>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Journal));
