import * as React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ReduxState from "../../../redux/ReduxState";
import { connect } from 'react-redux';
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar';
import { DrawerWidth } from '../../../App';

const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: DrawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: DrawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${DrawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    accountText: {
        marginLeft: theme.spacing.unit * 2,
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ICustomAppBarProps {
    onChangePassword: () => void,
    handleDrawerToggle: () => void,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface ICustomAppBarState {
    userDisplayName: string,
    anchorEl: any,
}

type Props = ICustomAppBarProps & WithStyles<typeof styles>;

class CustomAppBar extends React.Component<Props, ICustomAppBarState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            userDisplayName: "Gebruiker",
            anchorEl: null,
        }
    }

    componentDidMount = async () => {
        const userId = this.props.user.profile.sub;
        const getUserDisplayName = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/user/getuserdisplayname?id=" + userId).perform();
        await getUserDisplayName.json().then(data => this.setState({ userDisplayName: data }));
    }

    handleMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    public render() {
        const { classes } = this.props;
        const { userDisplayName, anchorEl } = this.state;

        const open = Boolean(anchorEl);

        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => this.props.handleDrawerToggle()}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" noWrap>
                        {this.props.configuration.clientSettings.appName}
            </Typography>
                    <div className={classes.toolbarButtons}>
                        <IconButton
                            aria-owns={open ? 'menu-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                            <Typography variant="subtitle1" color="inherit" noWrap className={classes.accountText}>
                                {userDisplayName}
                            </Typography>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={() => this.props.onChangePassword()}>Wachtwoord wijzigen</MenuItem>
                            <MenuItem onClick={() => this.props.auth.logout()}>Uitloggen</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomAppBar));
