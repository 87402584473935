import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const relationshipOptions = ["Vader", "Moeder", "Stiefvader", "Stiefmoeder", "Opa", "Oma", "Overig"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    }
});

export interface RelationshipSelectProps {
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean
}

type Props = RelationshipSelectProps & WithStyles<typeof styles>;

function RelationshipSelect(props: Props) {
    const { classes, onChange, value, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="relationship-select" required={required}>Relatie tot cliënt</InputLabel>
            <Select
                value={value}
                input={<Input name="relationshipToClient" id="relationship-select" />}
                onChange={onChange}
                displayEmpty
                name='relationshipToClient'
                className={classes.selectEmpty}
                placeholder="Selecteer relatie"
            >
                {relationshipOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(RelationshipSelect);