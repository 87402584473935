import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import UtilityString from "../../../utilities/UtilityString";

export const authorityOptions = ["Ja", "Nee", "Onbekend"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    }
});

export interface AuthoritySelectProps {
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean
}

type Props = AuthoritySelectProps & WithStyles<typeof styles>;

function AuthoritySelect(props: Props) {
    const { classes, onChange, value, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="authority-select" required={required}>Heeft gezag</InputLabel>
            <Select
                value={value}
                input={<Input name="authority" id="authority-select" />}
                onChange={onChange}
                displayEmpty
                name='authority'
                className={classes.selectEmpty}
                placeholder="Selecteer gezag"
            >
                {authorityOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(AuthoritySelect);