import React, { Component } from 'react';
import AuthRole from './auth/AuthRole';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles'
import CustomDrawer from './ui/components/navigation/CustomDrawer'
import CustomAppBar from './ui/components/navigation/CustomAppBar'
import { Route } from 'react-router';
import AuthorizedComponent from './auth/AuthorizedComponent';
import ChangePasswordDialog from './ui/components/dialogs/ChangePasswordDialog';
import ImportDataDialog from './ui/components/dialogs/ImportDataDialog';
import ImportAttachmentsDialog from './ui/components/dialogs/ImportAttachmentsDialog';

import ActiveUsers from './ui/pages/ActiveUsers';
import BlockedUsers from './ui/pages/BlockedUsers';
import Cases from './ui/pages/Cases';
import Case from './ui/pages/Case/Case';
import Clients from './ui/pages/Clients';
import Client from './ui/pages/Client/Client';
import Journal from './ui/pages/Journal';
import Calendar from './ui/pages/Calendar';
import Documents from './ui/pages/Documents';

export const DrawerWidth = 240;

const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: DrawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: DrawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${DrawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: DrawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    accountText: {
        marginLeft: theme.spacing.unit * 2,
    }
});

type Props = DrawerProps & WithStyles<typeof styles>;

class App extends Component<Props> {

    state = {
        mobileOpen: false,
        changePasswordDialogOpen: false,
        userDisplayName: "Gebruiker",
        anchorEl: null,
        importDataDialogOpen: false,
        importAttachmentsDialogOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    onNavigate = () => {
        if (this.state.mobileOpen) this.handleDrawerToggle();
    }

    onChangePassword = () => {
        this.setState({ changePasswordDialogOpen: true });
    }

    onImportdata = () => {
        this.setState({ importDataDialogOpen: true, })
    }

    onImportAttachments = () => {
        this.setState({ importAttachmentsDialogOpen: true, })
    }

    render() {
        const { classes, theme } = this.props;
        const { changePasswordDialogOpen, anchorEl, userDisplayName, importDataDialogOpen, importAttachmentsDialogOpen } = this.state;
        
        return (
            <AuthorizedComponent allowedRoles={[AuthRole.USER, AuthRole.DEVELOPER, AuthRole.ADMINISTRATOR, AuthRole.VOLUNTEER]}>
                <div className={classes.root}>
                    <CssBaseline />
                    <CustomAppBar
                        onChangePassword={this.onChangePassword}
                        handleDrawerToggle={this.handleDrawerToggle}
                    />
                    <nav className={classes.drawer}>
                        <Hidden lgUp implementation="css">
                            <Drawer
                                container={this.props.container}
                                variant="temporary"
                                anchor='left'
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <CustomDrawer onNavigate={this.onNavigate} onChangePassword={this.onChangePassword} onImportData={this.onImportdata} onImportAttachments={this.onImportAttachments} />
                            </Drawer>
                        </Hidden>
                        <Hidden mdDown implementation="css">
                            <Drawer
                                classes={{ paper: classes.drawerPaper }}
                                variant="permanent"
                                open
                            >
                                <CustomDrawer onNavigate={this.onNavigate} onChangePassword={this.onChangePassword} onImportData={this.onImportdata} onImportAttachments={this.onImportAttachments} />
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Route exact path='/users' component={ActiveUsers} />
                        <Route exact path='/blockedusers' component={BlockedUsers} />

                        <Route exact path='/' component={Cases} />
                        <Route exact path='/case/:caseId' component={Case} />

                        <Route exact path='/clients' component={Clients} />
                        <Route exact path='/client/:clientId' component={Client} />

                        <Route exact path='/journal' component={Journal} />
                        <Route exact path='/calendar' component={Calendar} />

                        <Route exact path='/document/:storeTitle' component={Documents} />

                        {changePasswordDialogOpen && (
                            <ChangePasswordDialog
                                open={changePasswordDialogOpen}
                                onClose={() => this.setState({ changePasswordDialogOpen: false, })}
                                onSuccess={() => this.setState({ changePasswordDialogOpen: false })}
                            />
                        )}

                        {importDataDialogOpen && (
                            <ImportDataDialog
                                open={importDataDialogOpen}
                                onClose={() => this.setState({ importDataDialogOpen: false, })}
                                onSuccess={() => this.setState({ importDataDialogOpen: false })}
                            />
                        )}

                        {importAttachmentsDialogOpen && (
                            <ImportAttachmentsDialog
                                open={importAttachmentsDialogOpen}
                                onClose={() => this.setState({ importAttachmentsDialogOpen: false, })}
                                onSuccess={() => this.setState({ importAttachmentsDialogOpen: false })}
                            />
                        )}

                    </main>
                </div>
            </AuthorizedComponent>
        );
    }
}

export default withStyles(styles, { withTheme: true })(App);



