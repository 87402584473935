import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CaseForm from '../../forms/CaseForm';

const styles = {
  appBar: {
    position: 'relative' as 'relative',
  },
  flex: {
    flex: 1,
  },
};

const addCaseFormId = 'form-add-case';

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

export interface AddCaseDialogProps {
  open: boolean,
  onClose: () => void,
  onAddCaseSuccess: () => void,
}

type Props = AddCaseDialogProps & WithStyles<typeof styles>;

class AddCaseDialog extends Component<Props> {

  onSubmitFail = () => {
  }

  render() {
    const { classes, onClose, onAddCaseSuccess, open } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={onClose} aria-label="Sluiten">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Casus toevoegen
              </Typography>
              <Button color="inherit" type="submit" form={addCaseFormId}>
                Toevoegen
              </Button>
            </Toolbar>
          </AppBar>

          <CaseForm 
          formId={addCaseFormId} 
          apiEndpoint="/case/add" 
          onSubmitFail={this.onSubmitFail} 
          onSubmitSuccess={onAddCaseSuccess} />

        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddCaseDialog);