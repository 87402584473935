import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const stateOptions = ["Lopend", "Afgehandeld"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    }
});

export interface StatusSelectProps {
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean
}

type Props = StatusSelectProps & WithStyles<typeof styles>;

function StatusSelect(props: Props) {
    const { classes, onChange, value, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="status-select" required={required}>Status</InputLabel>
            <Select
                value={value}
                input={<Input name="status" id="status-select" />}
                onChange={onChange}
                displayEmpty
                name='status'
                className={classes.selectEmpty}
                placeholder="Selecteer status"
            >
                {stateOptions.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(StatusSelect);