import Oidc from 'oidc-client';
import AbstractReduxAction from './AbstractReduxAction';
import Auth from '../../auth/Auth';

export default class OnAuthInitialized extends AbstractReduxAction
{
    public static readonly TYPE = "on_auth_initialized";

    public auth: Auth;

    constructor(auth: Auth)
    {
        super(OnAuthInitialized.TYPE);

        this.auth = auth;
    }
}
