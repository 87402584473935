import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ClientSelect from './Inputs/ClientSelect';
import VolunteerSelect from './Inputs/VolunteerSelect';
import TitleSelect from './Inputs/TitleSelect';
import SubTitleSelect from './Inputs/SubTitleSelect';
import StatusSelect from './Inputs/StatusSelect';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { CaseAdditionData } from '../../data/CaseAdditionData';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface CaseAdditionFormProps {
    formId: string,
    caseId: number,
    apiEndpoint: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    data?: CaseAdditionData,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface CaseAdditionFormErrors {
    [key: string]: any,
    firstVolunteerId: string,
    secondVolunteerId: string,
    entry: string,
}

export interface CaseAdditionFormState {
    id: number,
    caseId: number,
    entry: string,
    firstVolunteerId: string,
    secondVolunteerId: string,
    errors: CaseAdditionFormErrors
}

type Props = CaseAdditionFormProps & WithStyles<typeof styles>;

class CaseAdditionForm extends React.Component<Props, CaseAdditionFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            id: props.data ? props.data!.id : -1,
            caseId: props.caseId,
            firstVolunteerId: props.data ? props.data!.firstVolunteer.id.toString() : this.props.user.profile.sub,
            secondVolunteerId: props.data ? props.data!.secondVolunteer.id.toString() : '',
            entry: props.data ? props.data!.entry : '',
            errors: {
                firstVolunteerId: '',
                secondVolunteerId: '',
                entry: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<CaseAdditionFormState, keyof CaseAdditionFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.apiEndpoint, data).perform();

        if (response.ok) {
            console.log('success in form')
            this.props.onSubmitSuccess();
        } else {
            console.log('Failed in form')
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { firstVolunteerId, secondVolunteerId, entry } = this.state;
        let isValid = true;

        currentErrors.firstVolunteerId = UtilityString.nullOrEmpty(firstVolunteerId) ? "Eerste behandelaar is verplicht" : '';
        currentErrors.secondVolunteerId = UtilityString.nullOrEmpty(secondVolunteerId) ? "Tweede behandelaar is verplicht" : '';
        currentErrors.entry = UtilityString.nullOrEmpty(entry) ? "Update is verplicht" : '';

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId, caseId, data } = this.props;

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    {data != undefined &&
                        <React.Fragment>
                            <Input id="Id" name="Id" value={data!.id} className={classes.hidden} />
                            <Input id="timeStamp" name="timestamp" value={data!.timeStamp} className={classes.hidden} />
                        </React.Fragment>
                    }
                    <Input id="caseId" name="caseId" value={caseId} className={classes.hidden} />

                    <VolunteerSelect onChange={this.handleChange} label="Eerste behandelaar" name="firstVolunteerId" placeholder="Kies een vrijwilliger" value={this.state.firstVolunteerId} error={this.state.errors.firstVolunteerId} required />

                    <VolunteerSelect onChange={this.handleChange} label="Behandeld met" name="secondVolunteerId" placeholder="Kies een vrijwilliger" value={this.state.secondVolunteerId} error={this.state.errors.secondVolunteerId} required />

                    <TextInput
                        id="entry"
                        value={this.state.entry}
                        label="Update"
                        name="entry"
                        multiline
                        rows="4"
                        onChange={this.handleChange}
                        error={this.state.errors.entry}
                        required
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CaseAdditionForm));
