import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ClientParentForm from '../../forms/ClientParentForm';
import { ClientParentData } from '../../../data/ClientParentData';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const editParentFormId = 'form-edit-parent';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface EditClientParentDialogProps {
    clientId: number,
    open: boolean,
    data: ClientParentData,
    onClose: () => void,
    onSuccess: () => void,
}

type Props = EditClientParentDialogProps & WithStyles<typeof styles>;

class EditClientParentDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: Show snackbar or something like that.
    }

    render() {
        const { classes, onClose, onSuccess, open, clientId, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullScreen
                >
                <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={onClose} aria-label="Sluiten">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Ouder aanpassen
                            </Typography>
                            <Button color="inherit" type="submit" form={editParentFormId}>
                                Opslaan
                            </Button>
                        </Toolbar>
                    </AppBar>
                   
                        <ClientParentForm
                            formId={editParentFormId}
                            clientId={clientId}
                            data={data}
                            apiEndpoint="/parent/edit"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditClientParentDialog);