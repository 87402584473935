import * as React from 'react';
import { match } from 'react-router';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClientOverview from './ClientOverview';
import ClientSiblings from './ClientSiblings';
import ClientParents from './ClientParents';
import { ClientData } from '../../../data/ClientData';
import { ClientSiblingData } from '../../../data/ClientSiblingData';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EditClientDialog from "../../components/dialogs/EditClientDialog";
import { ClientParentData } from '../../../data/ClientParentData';
import UtilityRole from '../../../utilities/UtilityRole';
import AuthRole from '../../../auth/AuthRole';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme: Theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,

        flexGrow: 1,
    },
    button: {
    },
    toolBar: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
    backButton: {
        marginRight: 20,
    },
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ClientProps {
    match: match,
    history: History,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface ClientState {
    data: ClientData,
    siblings: ClientSiblingData[],
    parents: ClientParentData[],
    tabPage: number,
    editClientDialogOpen: boolean,
    deleteClientDialogOpen: boolean,
}

type Props = ClientProps & WithStyles<typeof styles>;

class Client extends React.Component<Props, ClientState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: {
                id: Number((props.match.params as any).clientId),
                firstName: '',
                lastName: '',
                lastNamePrefix: '',
                gender: '',
                dateOfBirth: '',
                placeOfBirth: '',
                email: '',
                phoneNumber: '',
                phoneNumberMobile: '',
                initials: '',
                streetName: '',
                houseNumber: '',
                postalCode: '',
                city: '',
                notes: '',
                age: '',
                primaryResidence: '',
                bsn: '',
                toestemming: '',
            },
            siblings: [],
            parents: [],
            tabPage: 0,
            editClientDialogOpen: false,
            deleteClientDialogOpen: false,
        }
    }

    componentDidMount = () => {
        this.loadClientData();
        this.loadSiblingData();
        this.loadParentData();
    }

    // Loading is done here otherwise the additions would be reloaded every time you switch tabs.
    loadClientData = async () => {
        const { id } = this.state.data;
        const getClient = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/client/get?id=" + id).perform();
        await getClient.json().then(data => {
            this.setState({ data })
        });
    }

    loadSiblingData = async () => {
        const { id } = this.state.data;
        const getClient = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/sibling/getminifiedforClient?ClientId=" + id).perform();
        await getClient.json().then((siblings: [any]) => {
            let newData = siblings.map(entry => {
                const sibling: ClientSiblingData = {
                    id: entry.id,
                    firstName: entry.firstName,
                    lastName: entry.lastName,
                    lastNamePrefix: entry.lastNamePrefix,
                    name: entry.name,
                    gender: entry.gender,
                    age: entry.age,
                    isClient: entry.isClient,
                    clientId: entry.clientId,
                }
                return sibling;
            });
            this.setState({ siblings: newData });
        });
    }

    loadParentData = async () => {
        const { id } = this.state.data;
        const getClient = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/parent/getallforclient?clientId=" + id).perform();
        await getClient.json().then((parents: [any]) => {
            this.setState({ parents: parents });
        });
    }

    onSiblingDataChanged = () => {
        this.loadSiblingData();
    }

    onParentDataChanged = () => {
        this.loadParentData();
    }

    onEditClientSuccess = () => {
        this.setState({ editClientDialogOpen: false })
        this.loadClientData();
    }

    onDeleteClientConfirm = async (id: any) => {
        const deleteCaseResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/client/delete?id=" + id).perform();
        if (deleteCaseResponse.ok) {
            this.props.history.push("/clients");
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ deleteClientDialogOpen: false });
    }

    public render() {
        const { classes } = this.props;
        const { data, tabPage, siblings, parents } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <AppBar position="static" color='default'>
                            <Toolbar className={classes.toolBar}>
                                <Tooltip title="Terug naar cliënten">
                                    <IconButton className={classes.backButton} aria-label="Back" onClick={() => this.props.history.goBack()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                                <Grid container spacing={16} alignItems='center'>

                                    <Grid item xs={12} sm={3}>
                                        <Typography component="p" color='textSecondary'>
                                            Voornaam
                                        </Typography>
                                        <Typography variant="h6">
                                            {data.firstName}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <Typography component="p" color='textSecondary'>
                                            Initialen
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.initials}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography component="p" color='textSecondary'>
                                            Leeftijd
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.age}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Typography component="p" color='textSecondary'>
                                            ID
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Tooltip title="Cliënt aanpassen">
                                    <IconButton className={classes.button} aria-label="Edit" onClick={() => this.setState({ editClientDialogOpen: true })}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                {UtilityRole.hasRole(this.props.user, AuthRole.ADMINISTRATOR) && (
                                    <Tooltip title="Cliënt verwijderen">
                                        <IconButton className={classes.button} aria-label="Delete" onClick={() => this.setState({ deleteClientDialogOpen: true })}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                            <Tabs
                                value={tabPage}
                                onChange={(event, value) => this.setState({ tabPage: value })}
                                indicatorColor="secondary"
                                textColor="secondary"
                                centered
                            >
                                <Tab label="Gegevens" />
                                <Tab label="Broers/zussen" />
                                <Tab label="Ouders" />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    {tabPage === 0 && (
                        <Grid item xs={12}>
                            <ClientOverview data={data} />
                        </Grid>
                    )}
                    {tabPage === 1 && (
                        <Grid item xs={12}>
                            <ClientSiblings clientId={data.id} siblings={siblings} onDataChanged={this.onSiblingDataChanged} />
                        </Grid>
                    )}
                    {tabPage === 2 && (
                        <Grid item xs={12}>
                            <ClientParents clientId={data.id} parents={parents} onDataChanged={this.onParentDataChanged} />
                        </Grid>
                    )}

                </Grid>

                <EditClientDialog
                    data={data}
                    open={this.state.editClientDialogOpen}
                    onClose={() => this.setState({ editClientDialogOpen: false })}
                    onEditClientSuccess={this.onEditClientSuccess}
                />

                <ConfirmDialog
                    additionId={this.state.data.id}
                    title="Cliënt verwijderen?"
                    message="Weet u zeker dat u deze cliënt wil verwijderen? Dit kan niet ongedaan worden gemaakt."
                    confirmButtonText="Verwijderen"
                    open={this.state.deleteClientDialogOpen}
                    onClose={() => this.setState({ deleteClientDialogOpen: false })}
                    onConfirm={this.onDeleteClientConfirm}
                />

            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Client));

