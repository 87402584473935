import * as React from 'react';
import { match } from 'react-router';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CaseOverview from './CaseOverview';
import { CaseData } from '../../../data/CaseData';
import CaseAdditions from './CaseAdditions';
import CaseAttachments from './CaseAttachments';
import EditCaseDialog from "../../components/dialogs/EditCaseDialog";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CaseAdditionData } from '../../../data/CaseAdditionData';
import { DocumentData } from '../../../data/DocumentData';
import UtilityRole from '../../../utilities/UtilityRole';
import AuthRole from '../../../auth/AuthRole';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import { AnyAction } from 'redux';

const styles = (theme: Theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,

        flexGrow: 1,
    },
    button: {
    },
    toolBar: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
    backButton: {
        marginRight: 20,
    },
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface CaseProps {
    match: match,
    history: History,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface CaseState {
    data: CaseData,
    additions: CaseAdditionData[],
    attachments: DocumentData[],
    tabPage: number,
    editCaseDialogOpen: boolean,
    deleteCaseDialogOpen: boolean,
}

type Props = CaseProps & WithStyles<typeof styles>;

class Case extends React.Component<Props, CaseState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: {
                id: Number((props.match.params as any).caseId),
                formattedId: '',
                obstructedClient: { id: 0, initials: '', age: '', gender: '' },
                timeStamp: '',
                firstVolunteer: { id: '', firstName: '', lastName: '' },
                secondVolunteer: { id: '', firstName: '', lastName: '' },
                title: '',
                titleOther: '',
                subtitle: '',
                subtitleOther: '',
                question: '',
                description: '',
                advice: '',
                status: '',
                toestemming: '',
                reachMethod: '',
            },
            additions: [],
            attachments: [],
            tabPage: 0,
            editCaseDialogOpen: false,
            deleteCaseDialogOpen: false,
        }
    }

    componentDidMount = () => {
        this.loadCaseData();
        this.loadAdditionData();
        this.loadAttachmentData();
    }

    loadCaseData = async () => {
        const { id } = this.state.data;
        const getCase = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/case/get?id=" + id).perform();
        await getCase.json().then(data => {
            //console.log(data);
            this.setState({ data })
        });
    }

    // Loading is done here otherwise the additions would be reloaded every time you switch tabs.
    loadAdditionData = async () => {
        const { id } = this.state.data;
        const getCase = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/caseaddition/getallforcase?caseid=" + id).perform();
        await getCase.json().then(additions => {
            this.setState({ additions })
        });
    }

    // Loading is done here otherwise the additions would be reloaded every time you switch tabs.
    onAdditionDataChanged = () => {
        this.loadAdditionData();
    }

    loadAttachmentData = async () => {
        const { id } = this.state.data;
        const getCase = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/caseattachment/getminifiedforcase?caseId=" + id).perform();
        await getCase.json().then((attachments: [any]) => {
            // Not sure why I'm doing it using object here but define a data class for the rest...
            let newData = attachments.map(entry => {
                const attachment: DocumentData = {
                    id: entry.id,
                    timestamp: entry.timestamp,
                    fileName: entry.fileName,
                    fileType: entry.fileType,
                    note: entry.note,
                }
                return attachment;
            });
            this.setState({ attachments: newData });
        });
    }

    onAttachmentDataChanged = () => {
        this.loadAttachmentData();
    }

    onEditCaseSuccess = () => {
        this.setState({ editCaseDialogOpen: false })
        this.loadCaseData();
    }

    onDeleteCaseConfirm = async (id: any) => {
        const deleteCaseResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/case/delete?id=" + id).perform();
        if (deleteCaseResponse.ok) {
            this.props.history.push("/");
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ deleteCaseDialogOpen: false });
    }

    public render() {
        const { classes } = this.props;
        const { data, tabPage, additions, attachments } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <AppBar position="static" color='default'>
                            <Toolbar className={classes.toolBar}>
                                <Tooltip title="Terug naar casussen">
                                    <IconButton className={classes.backButton} aria-label="Back" onClick={() => this.props.history.goBack()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                                <Grid container spacing={16} alignItems='center'>

                                    <Grid item xs={12} sm={4}>
                                        <Typography component="p" color='textSecondary'>
                                            Cliënt
                                        </Typography>
                                        <Typography variant="h6">
                                            {data.obstructedClient.id} - &nbsp;<b>{data.obstructedClient.initials}</b>, {data.obstructedClient.age} jaar <i>({data.obstructedClient.gender})</i>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <Typography component="p" color='textSecondary'>
                                            Titel
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.title}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Typography component="p" color='textSecondary'>
                                            ID
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.formattedId}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={10} sm={2}>
                                        <Typography component="p" color='textSecondary'>
                                            Status
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {data.status}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Tooltip title="Casus aanpassen">
                                    <IconButton className={classes.button} aria-label="Edit" onClick={() => this.setState({ editCaseDialogOpen: true })}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                {UtilityRole.hasRole(this.props.user, AuthRole.ADMINISTRATOR) && (
                                    <Tooltip title="Casus verwijderen">
                                        <IconButton className={classes.button} aria-label="Delete" onClick={() => this.setState({ deleteCaseDialogOpen: true })}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}

                            </Toolbar>
                            <Tabs
                                value={tabPage}
                                onChange={(event, value) => this.setState({ tabPage: value })}
                                indicatorColor="secondary"
                                textColor="secondary"
                                centered
                            >
                                <Tab label="Gegevens" />
                                <Tab label="Bijlagen" />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    {tabPage === 0 && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <CaseAdditions caseId={data.id} additions={additions} onDataChanged={this.onAdditionDataChanged} />
                            </Grid>

                            <Grid item xs={12}>
                                <CaseOverview data={data} />
                            </Grid>
                        </React.Fragment>
                    )}
                    {tabPage === 1 && (
                        <Grid item xs={12}>
                            <CaseAttachments caseId={data.id} attachments={attachments} onDataChanged={this.onAttachmentDataChanged} />
                        </Grid>
                    )}



                </Grid>

                <EditCaseDialog
                    data={data}
                    open={this.state.editCaseDialogOpen}
                    onClose={() => this.setState({ editCaseDialogOpen: false })}
                    onEditCaseSuccess={this.onEditCaseSuccess}
                />

                <ConfirmDialog
                    additionId={this.state.data.id}
                    title="Casus verwijderen?"
                    message="Weet u zeker dat u deze casus wil verwijderen? Dit kan niet ongedaan worden gemaakt."
                    confirmButtonText="Verwijderen"
                    open={this.state.deleteCaseDialogOpen}
                    onClose={() => this.setState({ deleteCaseDialogOpen: false })}
                    onConfirm={this.onDeleteCaseConfirm}
                />
        
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Case));

