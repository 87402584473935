import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import JournalUpdateForm from '../../forms/JournalUpdateForm';
import { JournalUpdateData } from '../../../data/JournalUpdateData';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
};

const editJournalUpdateForm = 'form-edit-journal-update';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface EditJournalUpdateDialogProps {
    data: JournalUpdateData
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
}

type Props = EditJournalUpdateDialogProps & WithStyles<typeof styles>;

class EditJournalUpdateDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: show snackbar or something like that
    }

    render() {
        const { classes, onClose, onSuccess, open, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="dialog-title">Update aanpassen</DialogTitle>
                    <DialogContent>
                        <JournalUpdateForm
                            formId={editJournalUpdateForm}
                            data={data}
                            apiEndpoint="/journal/edit"
                            onSubmitFail={this.onSubmitFail}
                            onSubmitSuccess={onSuccess} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Annuleren</Button>
                        <Button color="secondary" type="submit" form={editJournalUpdateForm}>Opslaan</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditJournalUpdateDialog);