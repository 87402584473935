import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import VolunteerSelect from './Inputs/VolunteerSelect';
import TextInput from './Inputs/TextInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import FileInput from './Inputs/FileInput';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface GeneralDocumentFormProps {
    formId: string,
    storeFilter: string,
    apiEndpoint: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface GeneralDocumentFormErrors {
    [key: string]: any,
    file: string,
    note: string,
}

export interface GeneralDocumentFormState {
    note: string,
    file: string,
    errors: GeneralDocumentFormErrors
}

type Props = GeneralDocumentFormProps & WithStyles<typeof styles>;

class GeneralDocumentForm extends React.Component<Props, GeneralDocumentFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            file: '',
            note: '',
            errors: {
                file: '',
                note: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<GeneralDocumentFormState, keyof GeneralDocumentFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.apiEndpoint, data).perform();

        if (response.ok) {
            console.log('success in form')
            this.props.onSubmitSuccess();
        } else {
            console.log('Failed in form')
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let currentErrors = { ...this.state.errors }
        const { file, note } = this.state;
        let isValid = true;

        currentErrors.file = UtilityString.nullOrEmpty(file) ? "Upload een bestand" : '';

        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId, storeFilter} = this.props;

        return (
            <React.Fragment>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    <Input id="storeFilter" name="storeFilter" value={storeFilter} className={classes.hidden} />

                    <FileInput
                        id="file"
                        value={this.state.file}
                        label="Bestand"
                        name="file"
                        onChange={this.handleChange}
                        error={this.state.errors.file}
                        required
                    />

                    <TextInput
                        id="note"
                        value={this.state.note}
                        label="Notitie"
                        name="note"
                        multiline
                        rows="4"
                        onChange={this.handleChange}
                        error={this.state.errors.note}                        
                    />

                </form>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(GeneralDocumentForm));
