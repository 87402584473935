import { DocumentStore } from "../redux/entities/DocumentStore";

export default class UtilityDocumentStore
{
    static parseDocumentStores = (value: string) => {
        return value.split(';').map(e => {
            const storeStrings = e.split(',');
            const store: DocumentStore = {
                title: storeStrings[0],
                filter: storeStrings[1],
                number: 1,
            }
            return store;
        });
    }
}