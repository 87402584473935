import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const stateOptions = [{label: "Man", value: "M"}, {label: "Vrouw", value: "V"}, {label: "Overig", value: "O"}, {label: "Onbekend", value: "-"}];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
    }
});

export interface GenderSelectProps {
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean
}

type Props = GenderSelectProps & WithStyles<typeof styles>;

function GenderSelect(props: Props) {
    const { classes, onChange, value, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="gender-select" required={required}>Geslacht</InputLabel>
            <Select
                value={value}
                input={<Input name="gender" id="gender-select" />}
                onChange={onChange}
                displayEmpty
                name='gender'
                className={classes.selectEmpty}
                placeholder="Selecteer geslacht"
            >
                {stateOptions.map(gender => (
                    <MenuItem key={gender.value} value={gender.value}>{gender.label}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(GenderSelect);