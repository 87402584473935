import * as React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../theme/Localization';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from '../../utilities/UtilityString';
import queryString from 'querystring';
import { UserData } from '../../data/UserData';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import UnBlockIcon from "@material-ui/icons/SettingsBackupRestore";
import ConfirmDialog from '../components/dialogs/ConfirmDialog';

const columns = [
  {
    name: "ID",
    label: "ID",
    options: {
      display: 'excluded' as 'excluded',
      filter: false,      
    }
  },
  {
    name: "FirstName",
    label: "Naam",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "Email",
    label: "E-mailadres",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "actions",
    label: " ",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        return (<React.Fragment />);
      },
      setCellProps: () => {
        return {
          align: 'right',
        }
      }
    },
  },
];

const allowedTableChangedActions = ["filterChange", "resetFilters", "changePage", "changeRowsPerPage", "sort", "search"];

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface BlockedUsersProps {
  history: History,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface BlockedUsersState {
  data: UserData[],
  rowsPerPage: number,
  totalRowCount: number,
  unBlockDialogOpen: boolean,
  unBlockId: string,
}

class BlockedUsers extends React.Component<BlockedUsersProps, BlockedUsersState> {

  constructor(props: BlockedUsersProps) {
    super(props);

    this.state = {
      data: [],
      rowsPerPage: 10,
      totalRowCount: 0,
      unBlockDialogOpen: false,
      unBlockId: "",
    };
  }

  componentDidMount = () => {
    const { rowsPerPage } = this.state;
    this.loadData(columns[0].name, 'desc', [], 0, rowsPerPage, '');
  }

  loadData = async (sortedColumn: string, sortDirection: string, columnFilters: string[], page: number, rowsPerPage: number, searchText: string) => {
    const requestBody = { sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText };
    const queryParams = queryString.stringify(requestBody)

    const getBlockedUsers = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/user/getAllBlockedUsers?" + queryParams).perform();
    await getBlockedUsers.json().then(data => this.onDataReceived(data));
  }

  onTableChange = async (action: string, tableState: any) => {

    if (!allowedTableChangedActions.includes(action)) {
      return;
    }

    let sortedColumn: string = columns[0].name;
    let sortDirection: string = 'desc';
    let columnFilters: string[] = [];
    let page: number = tableState.page;
    let rowsPerPage: number = tableState.rowsPerPage;
    let searchText: string = tableState.searchText;

    if (tableState.activeColumn) {
      sortedColumn = tableState.columns[tableState.activeColumn].name;
      sortDirection = tableState.columns[tableState.activeColumn].sortDirection;
    }

    for (let i = 0; i < tableState.columns.length; i++) {
      const columnObject: any = tableState.columns[i];
      const filterValue = tableState.filterList[i][0];
      if (!UtilityString.nullOrEmpty(filterValue)) {
        columnFilters.push(columnObject.name + "," + filterValue);
      }
    }

    this.loadData(sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText);
  }

  onDataReceived = (receivedData: any) => {
    let rowsPerPage = receivedData.rowsPerPage;
    let totalRowCount = receivedData.totalRowCount;
    let receivedDataEntries: [any] = receivedData.data;
    this.setState({ data: receivedDataEntries, totalRowCount, rowsPerPage });
  }

  onConfirmUnBlockUser = async (id: string) => {
    const { unBlockId } = this.state;
    const unblockUserResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwIdentity + "/account/unblockuser?id=" + unBlockId).perform();
    if (unblockUserResponse.ok) {
      const updateUserStateResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/user/userunblocked?id=" + unBlockId).perform();
      this.componentDidMount();
    } else {
      // TODO: Show snackbar that something went wrong or something like that
    }
    this.setState({ unBlockDialogOpen: false, unBlockId: "" });
  }

  renderActionColBody = (value: any, tableMeta: any, updateValue: any) => {
    return (
      <Tooltip title={"Deblokkeren"}>
        <IconButton onClick={() => this.setState({ unBlockDialogOpen: true, unBlockId: tableMeta.rowData[0] })}>
          <UnBlockIcon />
        </IconButton>
      </Tooltip>
    );
  }

  public render() {
    const { data, rowsPerPage, totalRowCount, unBlockDialogOpen, unBlockId } = this.state;

    // Have to set this here as there is no context in the global scope and the clicks require it.
    columns[3].options.customBodyRender = this.renderActionColBody

    const options: MUIDataTableOptions = {
      filter: false,
      filterType: "dropdown",
      responsive: "stacked",
      download: false,
      print: false,
      selectableRows: false,
      textLabels: TableTextLabels,
      count: totalRowCount,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
      serverSide: true,
      onTableChange: this.onTableChange,
    };

    return (
      <div>
        <React.Fragment>
          <MUIDataTable
            title={"Geblokkeerde gebruikers"}
            data={data.map(user => [user.id, user.firstName + " " + user.lastName, user.email])}
            columns={columns}
            options={options}
          />

          {!UtilityString.nullOrEmpty(unBlockId) && (
            <ConfirmDialog
              additionId={unBlockId}
              title="Gebruiker deblokkeren?"
              message="Het wachtwoord en de 2FA van de gebruiker worden gereset, waarna de gebruiker weer kan inloggen."
              confirmButtonText="Deblokkeren"
              open={unBlockDialogOpen}
              onClose={() => this.setState({ unBlockDialogOpen: false, unBlockId: "" })}
              onConfirm={this.onConfirmUnBlockUser}
            />
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default connect(mapStateToProps)(BlockedUsers);
