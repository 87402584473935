import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

export const titleOptions = ["Strafrecht", "Familierecht", "Arbeidsrecht", "Contractenrecht", "Bestuursrecht", "Privaatrecht", "Overig"];

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    },
    chips: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
});

export interface TitleSelectProps {
    onChange: (event: any) => void,
    values: string[],
    error?: string,
    required?: boolean,
}

type Props = TitleSelectProps & WithStyles<typeof styles>;

function TitleSelect(props: Props) {
    const { classes, onChange, values, error, required } = props;

    return (
        <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
            <InputLabel htmlFor="title-select" required={required}>Titel</InputLabel>
            <Select
                multiple
                value={values}
                input={<Input name="title" id="title-select" />}
                onChange={onChange}
                displayEmpty
                name='title'
                className={classes.selectEmpty}
                placeholder="Kies een titel"
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {(selected! as string[]).map(value => <Chip key={value} label={value} className={classes.chip} />)}
                    </div>
                )}
            >
                {titleOptions.map(title => (
                    <MenuItem key={title} value={title}>{title}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default withStyles(styles)(TitleSelect);