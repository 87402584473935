import HttpMethod from "../http/enums/HttpMethod";

export default class ApiRequest
{
    private user?: Oidc.User;
    private method: HttpMethod;
    private url: string;
    private body?: any;

    constructor(user: Oidc.User | undefined, method: HttpMethod, url: string, body?: any)
    {
        this.user = user;
        this.method = method;
        this.url = url;
        this.body = body;
    }

    public perform = async () =>
    {
        const options: RequestInit = this.getOptions();
        const response = await fetch(this.url, options);

        return response;
    }

    private getOptions = (): RequestInit =>
    {
        var options: RequestInit = {
            method: this.method.name,
            mode: "cors",
            cache: "no-cache",
            body: this.body,
            credentials: "include"
        };

        if (this.user)
        {
            options.headers = new Headers({ 'Authorization': 'Bearer ' + this.user.access_token });
        }

        return options;
    }
}