import * as React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../theme/Localization';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import ToolbarItemAdd from '../components/table/ToolbarItemAdd';
import UtilityString from '../../utilities/UtilityString';
import queryString from 'querystring';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import AddClientDialog from '../components/dialogs/AddClientDialog';

const columns = [
  {
    name: "Id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      sortDirection: 'desc' as 'desc'
    }
  },
  {
    name: "FirstName",
    label: "Naam",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "Gender",
    label: "Geslacht",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "Age",
    label: "Leeftijd",
    options: {
      filter: false,
      sort: false,
    }
  },
];

const allowedTableChangedActions = ["filterChange", "resetFilters", "changePage", "changeRowsPerPage", "sort", "search"];

const styles = (theme: Theme) => ({
  fab: {
    margin: theme.spacing.unit,
    position: 'fixed' as 'fixed',
    right: 20,
    bottom: 20,
  },
});

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface ClientsProps {
  history: History,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface ClientsState {
  data: object[][],
  rowsPerPage: number,
  totalRowCount: number,
  addClientDialogOpen: boolean,
}

type Props = ClientsProps & WithStyles<typeof styles>;

class Clients extends React.Component<Props, ClientsState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      rowsPerPage: 10,
      totalRowCount: 0,
      addClientDialogOpen: false,
    };
  }

  componentDidMount = () => {
    const { rowsPerPage } = this.state;
    this.loadData(columns[0].name, 'desc', [], 0, rowsPerPage, '');
  }

  loadData = async (sortedColumn: string, sortDirection: string, columnFilters: string[], page: number, rowsPerPage: number, searchText: string) => {
    const requestBody = { sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText };
    const queryParams = queryString.stringify(requestBody)

    const getClients = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/client/getallminified?" + queryParams).perform();
    await getClients.json().then(data => this.onDataReceived(data));
  }

  onRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
    this.props.history.push("/client/" + rowData[0]);
  }

  onTableChange = async (action: string, tableState: any) => {

    if (!allowedTableChangedActions.includes(action)) {
      return;
    }

    let sortedColumn: string = columns[0].name;
    let sortDirection: string = 'desc';
    let columnFilters: string[] = [];
    let page: number = tableState.page;
    let rowsPerPage: number = tableState.rowsPerPage;
    let searchText: string = tableState.searchText;

    if (tableState.activeColumn) {
      sortedColumn = tableState.columns[tableState.activeColumn].name;
      sortDirection = tableState.columns[tableState.activeColumn].sortDirection;
    }

    for (let i = 0; i < tableState.columns.length; i++) {
      const columnObject: any = tableState.columns[i];
      const filterValue = tableState.filterList[i][0];
      if (!UtilityString.nullOrEmpty(filterValue)) {
        columnFilters.push(columnObject.name + "," + filterValue);
      }
    }

    this.loadData(sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText);
  }

  onDataReceived = (receivedData: any) => {
    let rowsPerPage = receivedData.rowsPerPage;
    let totalRowCount = receivedData.totalRowCount;
    let receivedDataEntries: [any] = receivedData.data;
    let newData = receivedDataEntries.map(entry => [entry.id, entry.name, entry.gender, entry.age]);
    this.setState({ data: newData, totalRowCount, rowsPerPage });
  }

  onAddClientsuccess = () => {
    this.setState({ addClientDialogOpen: false })
    // Probably should just add the single case but this is easier when filters/searchqueries and sorting is used.
    // Should look into keeping the table state, although resetting the table state would make sense if you alter the data in it.
    this.loadData(columns[0].name, 'desc', [], 0, this.state.rowsPerPage, '');
  }

  onCaseDialogClose = () => {
    this.setState({ addClientDialogOpen: false })
  }

  openAddClientDialog = () => {
    this.setState({ addClientDialogOpen: true });
  }

  public render() {
    const { classes } = this.props;
    const { data, rowsPerPage, totalRowCount } = this.state;

    const options: MUIDataTableOptions = {
      filter: false,
      filterType: "dropdown",
      responsive: "stacked",
      download: false,
      print: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      textLabels: TableTextLabels,
      count: totalRowCount,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
      customToolbar: () => {
        return (
          <ToolbarItemAdd
            tooltip="Cliënt toevoegen"
            handleClick={this.openAddClientDialog}
          />
        );
      },
      serverSide: true,
      onTableChange: this.onTableChange,
    };

    return (
      <div>
        <React.Fragment>
          <MUIDataTable
            title={"Cliënten"}
            data={data}
            columns={columns}
            options={options}
          />
          <AddClientDialog
            open={this.state.addClientDialogOpen}
            onClose={this.onCaseDialogClose}
            onAddClientSuccess={this.onAddClientsuccess}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Clients));
