import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { JournalUpdateData } from '../../../data/JournalUpdateData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
    appBar: {
        position: 'relative' as 'relative',
    },
    flex: {
        flex: 1,
    },
    textBody: {
        whiteSpace: 'pre-line' as 'pre-line',
    }
};

const editJournalUpdateForm = 'form-edit-journal-update';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export interface ViewJournalUpdateDialogProps {
    data: JournalUpdateData
    open: boolean,
    onClose: () => void,
}

type Props = ViewJournalUpdateDialogProps & WithStyles<typeof styles>;

class ViewJournalUpdateDialog extends Component<Props> {

    onSubmitFail = () => {
        // TODO: show snackbar or something like that
    }

    render() {
        const { classes, onClose, open, data } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog-title"
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="dialog-title">{data.subject}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={32}>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    ID
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {data.id}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary' >
                                    Aangemaakt op
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.timestamp}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography component="p" color='textSecondary'>
                                    Aangemaakt door
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.volunteer.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography component="p" color='textSecondary'>
                                    Onderwerp
                                </Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.subject}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography component="p" color='textSecondary'>Update</Typography>
                                <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                                    {data.entry}
                                </Typography>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">Sluiten</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ViewJournalUpdateDialog);