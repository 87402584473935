import Oidc from 'oidc-client';
import AuthRole from '../auth/AuthRole';

export default class UtilityRole
{

    static hasRole = (user: Oidc.User, role: AuthRole) => {
        for (var r = 0; r < user.profile.role.length; r++)
        {
            var currentRole = AuthRole.fromName(user.profile.role[r] as string);
            if(currentRole != undefined && currentRole === role) {
                return true;
            }
        }
        return false;
    }
}