import React, { Component } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import ReduxState from '../redux/ReduxState';
import Auth from './Auth';
import AuthRole from './AuthRole';


const mapStateToProps = (state: ReduxState) =>
{
    return { user: state.user, auth: state.auth };
};

interface Props
{
    user: Oidc.User | undefined,
    auth: Auth | undefined,
    allowedRoles: Array<AuthRole>
}

interface State
{

}

class AuthorizedComponent extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
    }

    render()
    {        
        var allowed = false;
        if (this.props.user)
        {
            const expires = this.props.user.expires_at;
            const date = new Date();
            const time = date.getTime() / 1000;

            if (expires > time)
            {
                for (var a = 0; a < this.props.allowedRoles.length; a++)
                {
                    var currentAllowed = this.props.allowedRoles[a];
                    for (var r = 0; r < this.props.user.profile.role.length; r++)
                    {
                        var currentRole = AuthRole.fromName(this.props.user.profile.role[r] as string);
                        if (currentRole != undefined && currentRole === currentAllowed)
                        {
                            allowed = true;
                            break;
                        }
                    }

                    if (allowed)
                    {
                        break;
                    }
                }
            }
        }

        if (allowed)
        {
            return (
                <div className="authorized">
                    {this.props.children}
                </div>
            );
        }
        else
        {
            if (this.props.auth)
            {
                this.props.auth.login();
            }

            return null;
        }
    }

}

export default connect(mapStateToProps)(AuthorizedComponent);;
