import * as React from 'react';
import { CaseData } from '../../../data/CaseData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme: Theme) => ({
    card: {
        padding: theme.spacing.unit * 2,
    },
    cardHeader: {
        marginBottom: theme.spacing.unit * 2,
    },
    textBody: {
        whiteSpace: 'pre-line' as 'pre-line',
    }
});

export interface CaseOverviewProps {
    data: CaseData
}

type Props = CaseOverviewProps & WithStyles<typeof styles>;

function CaseOverview(props: Props) {
    const { data, classes } = props;

    let subtitleText = data.subtitle;
    if(subtitleText != null && subtitleText.includes("Overig")) {
        subtitleText = subtitleText.replace("Overig", data.subtitleOther);
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container spacing={16} alignItems='center' className={classes.cardHeader}>
                    <Grid item xs={1}>
                        <Typography variant="h6">
                            Casus
                            </Typography>
                    </Grid>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Aangemaakt op
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.timeStamp}
                        </Typography>
                    </Grid>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Eerste behandelaar
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.firstVolunteer.firstName + " " + data.firstVolunteer.lastName}
                        </Typography>
                    </Grid>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Tweede behandelaar
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.secondVolunteer.firstName + " " + data.secondVolunteer.lastName}
                        </Typography>
                    </Grid>
                </Grid>


                <Grid container spacing={32}>

                    <Grid item xs={12}>
                        <Typography component="p" color='textSecondary'>
                            Subcategorie
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {subtitleText}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} >
                        <Typography component="p" color='textSecondary' >
                            Vraag
                        </Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.question}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} >
                        <Typography component="p" color='textSecondary'>
                            Toestemming
                        </Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.toestemming}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography component="p" color='textSecondary'>
                            Omschrijving
                        </Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.description}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography component="p" color='textSecondary'>Advies</Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.advice}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography component="p" color='textSecondary'>Hoe is cliënt bij ons terechtgekomen?</Typography>
                        <Typography variant="body1" paragraph align="justify" className={classes.textBody}>
                            {data.reachMethod}
                        </Typography>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(CaseOverview);
