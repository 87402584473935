export const TableTextLabels = {
    body: {
      noMatch: "Sorry, geen items gevonden",
      toolTip: "Sorteren",
    },
    pagination: {
      next: "Volgende pagina",
      previous: "Vorige pagina",
      rowsPerPage: "Rijen per pagina:",
      displayRows: "van",
    },
    toolbar: {
      search: "Zoeken",
      downloadCsv: "Download CSV",
      print: "Printen",
      viewColumns: "Kolommen",
      filterTable: "Filter",
    },
    filter: {
      all: "Alles",
      title: "FILTERS",
      reset: "RESET",
    },
    viewColumns: {
      title: "Getoonde Kolommen",
      titleAria: "Toon/Verberg Kolommen",
    },
    selectedRows: {
      text: "rij(en) geselecteerd",
      delete: "Verwijderen",
      deleteAria: "Verwijder geselecteerde rijen",
    },
  }